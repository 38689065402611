import {FieldContext} from '@webfox/webfox-ui';
import Paint from 'Components/Paint/Paint';
import {useField} from 'formik';
import LZString from 'lz-string';
import React, {useContext, useMemo, useRef} from 'react';

const PaintField = ({saveData: compressedSaveData, ...props}) => {
  const {name} = useContext(FieldContext);
  const [field, {initialValue}, {setValue, setTouched}] = useField(name);

  const canvasRef = useRef();

  const onChange = () => {
    setValue(LZString.compressToBase64(canvasRef.current?.getSaveData()));
    setTouched(true);
  };

  const saveData = useMemo(() => (
    (compressedSaveData || initialValue) ? LZString.decompressFromBase64(compressedSaveData || initialValue) : null
  ), [compressedSaveData, initialValue])

  return (
    <div>
      <Paint
        {...props}
        immediateLoading={true}
        saveData={saveData}
        canvasRef={canvasRef}
        onChange={onChange}
      />
      <div className="text-sm text-gray-500 mt-2">Warning: Save/Load times may be adversely affected by complex drawings</div>
    </div>
  );
};

export default PaintField;