import {Link} from '@inertiajs/inertia-react';
import cls from 'classnames';
import Product from 'Components/Product';
import React from 'react';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const MistakeFor = ({entityType, entity}) => {
  const user = useUser();

  return {
    job: () => (
      <Link href={!!entity && route('jobs.edit', entity?.id)}
            className={cls('block p-3 bg-white border rounded shadow-sm duration-100 border-primary', user?.isAdmin && 'hover:opacity-80')}
            disabled={!user?.isAdmin}>
        <div className="text-lg font-medium">Work Order #{entity?.id?.toString()?.padStart(8, '0')}</div>
        <div>{entity?.customer?.name}</div>
      </Link>
    ),
    job_product: () => (
      <Link href={!!entity && route('jobs.edit', entity?.job_id)}
            className={cls('block p-3 bg-white border rounded shadow-sm duration-100 border-primary', user?.isAdmin && 'hover:opacity-80')}
            disabled={!user?.isAdmin}>
        <div className="text-lg font-medium">Work Order #{entity?.job_id?.toString()?.padStart(8, '0')}</div>
        <div>{entity?.job?.customer?.name}</div>
        <div className="border-t mt-2 pt-2">
          <Product {...entity?.product} {...entity}/>
        </div>
      </Link>
    ),
  }[entityType]?.();
};

export default MistakeFor;