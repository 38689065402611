import {ConfirmationModal, FieldContext, Formik, PrimaryButton, useInertiaForm} from '@webfox/webfox-ui';
import DrawingModalField from 'Components/Forms/DrawingModalField';
import {Form} from 'formik';
import React, {useState} from 'react';

const DrawingModal = ({open, onClose, drawings, route, loading}) => {
  const [closing, setClosing] = useState(false);
  const [saving, setSaving] = useState(false);

  const formikProps = useInertiaForm({
    defaultValues: {
      drawings: drawings || [],
    },
    submit: ({put, reset}) => {
      setSaving(true);
      put(route, {
        onSuccess: () => {
          reset();
          onClose();
        },
        onFinish: () => {
          setSaving(false);
        },
      });
    },
  });

  return (
    <>
      <Formik {...formikProps}>
        {({submitForm}) => (
          <Form>
            <FieldContext.Provider value={{name: 'drawings'}}>
              <DrawingModalField
                open={open}
                onClose={() => setClosing(true)}
                actions={<PrimaryButton onClick={() => submitForm()}>Save</PrimaryButton>}
                loading={loading || saving}
              />
            </FieldContext.Provider>
          </Form>
        )}
      </Formik>
      <ConfirmationModal
        onCancel={() => setClosing(false)}
        onConfirm={() => {
          setClosing(false);
          onClose();
        }}
        open={closing}
        title="Are you sure?"
        confirmText="Close Without Saving"
      >
        You have unsaved changes. Are you sure you want to close this modal?
      </ConfirmationModal>
    </>
  );
};

export default DrawingModal;