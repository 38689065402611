import { Link } from '@inertiajs/inertia-react';
import { mdiChevronDown } from '@mdi/js';
import { Icon } from '@mdi/react';
import cls from 'classnames';
import React from 'react';
import route from 'ziggy-js';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';


export const isActiveRoute = (...names) => names.some(name => route().current(...(Array.isArray(name) ? name : [name])));

export const OptionMenuItem = ({icon, text, route: hrefRoute, isActive, action, inactiveTextClasses = 'text-gray-900'}) => {
  const Component = action ? 'button' : Link;

  if (hrefRoute && action) throw new Error('OptionMenuItem can only have one of route or action');

  return (
    <DropdownMenu.Item asChild>
        <Component
          {...(hrefRoute && {href: hrefRoute})}
          {...(action && {onClick: action})}
          className={cls('flex items-center w-full gap-2 px-3 py-2 text-sm font-medium rounded whitespace-nowrap duration-100', isActive ? 'text-primary bg-gray-50 pointer-events-none' : 'data-[highlighted]:bg-primary data-[highlighted]:text-white')}
          disabled={isActive}
        >
          <Icon path={icon} className="w-4"/>
          <div>{text}</div>
        </Component>
    </DropdownMenu.Item>
  );
};

const OptionsMenu = ({ children }) => (
  <DropdownMenu.Root modal={false}>
    <DropdownMenu.Trigger
      asChild
      name="Options"
      className="flex items-center px-3 py-2 font-medium text-sm duration-150 bg-indigo-50 hover:bg-indigo-100 rounded select-none"
    >
      <button>
        <div>Options</div>
        <Icon path={mdiChevronDown} className="w-4" />
      </button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Portal>
      <DropdownMenu.Content className="divide-y divide-gray-100 rounded-md bg-white border shadow-lg px-1 py-1" align="end" style={{
        zIndex: 99999,
      }}>
        <div>
          {children}
        </div>
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
);

export default OptionsMenu;
