import {mdiCheck, mdiClose, mdiCloseThick, mdiDownload, mdiOpenInNew} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'classnames';
import FileSystemObjectPreview from 'Components/FileSystem/FileSystemObjectPreview';
import React, {useEffect, useRef} from 'react';
import {siMicrosoftsharepoint} from 'simple-icons';
import {formatBytes} from 'Support/valueFormatters';

const ObjectOption = ({as = 'button', icon, text, children, ...props}) => {
  const Element = as;

  return (
    <Element {...props} className="flex items-center gap-2 p-2 rounded font-medium text-blue-600 duration-150 hover:bg-blue-100">
      {icon && <Icon path={icon} className="w-5"/>}
      {text || children}
    </Element>
  );
};

const FileSystemObjectPanelContent = ({object, onSelect, selected, onClose}) => {
  const lastObject = useRef(object);

  useEffect(() => {
    if (object) {
      lastObject.current = object;
    }
  }, [object]);

  const shownObject = object ?? lastObject.current;
  const imageInfo = shownObject?.extra_metadata?.image;

  return (
    <div
      className={cls('relative flex flex-col items-center gap-6 p-10 min-w-96 min-h-fit h-full border-l duration-300 overflow-y-auto', selected && 'border-blue-500')}>
      {onClose &&
        <button type="button" onClick={onClose} className="absolute top-1 left-1 p-2 hover:opacity-80">
          <Icon path={mdiClose} className="w-6 text-gray-500"/>
        </button>
      }
      <a href={shownObject?.openUrl || shownObject?.previewUrl} target="_blank"
         className={cls('group relative block w-48 h-48 min-w-48 min-h-48 p-2 border hover:border-blue-500 shadow-md rounded duration-100', !shownObject && 'pointer-events-none')}>
        <FileSystemObjectPreview object={shownObject}/>
        {imageInfo &&
          <div
            className="absolute inset-x-0 bottom-0 flex flex-col items-center translate-y-1/2 text-xs font-medium text-gray-500 pointer-events-none">
            <div className="flex items-center gap-1 p-1 bg-white rounded shadow border group-hover:border-blue-500 duration-100">
              <div>{imageInfo?.width}</div>
              <Icon path={mdiCloseThick} className="w-2"/>
              <div>{imageInfo?.height}</div>
            </div>
          </div>
        }
      </a>
      <span className="text-xs text-gray-500">{shownObject?.path}</span>
      {onSelect &&
        <button type="button" onClick={() => onSelect(shownObject)}
                className={cls('flex items-center gap-5 p-2 pr-5 font-medium duration-150 rounded-xl', selected ? 'bg-blue-500 text-white hover:opacity-80' : 'bg-gray-200 hover:bg-blue-100 text-gray-500')}>
          <div className={cls('w-6 h-6 min-w-6 min-h-6 text-white rounded-full duration-100', !selected && 'bg-white')}>
            <Icon path={mdiCheck} className="w-6 h-6"/>
          </div>
          <div className="font-medium">
            <span>Select</span>
            <span className={cls('duration-100', !selected && 'opacity-0')}>ed</span>
          </div>
        </button>
      }
      <table className="[&_th]:text-right [&_th]:pr-4 [&_th]:align-top text-gray-700">
        <tbody>
        <tr>
          <th>Name:</th>
          <td>{shownObject?.path?.split('/')?.slice(-1)[0]}</td>
        </tr>
        <tr>
          <th>Size:</th>
          <td>{formatBytes(shownObject?.file_size)}</td>
        </tr>
        <tr>
          <th>Type:</th>
          <td>{shownObject?.mime_type}</td>
        </tr>
        </tbody>
      </table>
      <div className="flex flex-col self-stretch gap-1">
        <ObjectOption as="a" href={shownObject?.openUrl || shownObject?.previewUrl} target="_blank" icon={mdiOpenInNew} text="Open"/>
        {!!shownObject?.extra_metadata?.sharepointUrl &&
          <ObjectOption as="a" href={shownObject.extra_metadata.sharepointUrl} target="_blank" icon={siMicrosoftsharepoint.path}
                        text="Open in Sharepoint"/>
        }
        <ObjectOption as="a" href={shownObject?.extra_metadata?.downloadUrl || shownObject?.openUrl || shownObject?.previewUrl} icon={mdiDownload}
                      text="Download" download/>
      </div>
    </div>
  );
};

export default FileSystemObjectPanelContent;