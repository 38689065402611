import Product from 'Components/Product';
import React from 'react';

const JobFileAttachedModel = ({model}) => {
  const elements = {
    'job': (
      <div className="flex flex-col items-center px-3 py-2 border border-indigo-500 bg-indigo-100 rounded">
        <div className="font-medium text-lg">Whole Work Order</div>
        <div className="text-sm text-blue-900">#{model?.id?.toString()?.padStart(8, '0')}</div>
      </div>
    ),
    'job_product': (
      <div className="flex flex-col px-3 py-2 border bg-white rounded shadow-sm min-w-max">
        <Product
          {...model?.product}
          {...model}
          price={model?.price || model?.product?.price}
          tags={model?.product?.tags}
          jobProductTags={model?.tags}
        />
      </div>
    ),
  };

  return elements[model?.attached_type];
};

export default JobFileAttachedModel;