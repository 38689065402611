import {TextareaField, TextField, VerticalFieldWrapper} from '@webfox/webfox-ui';
import axios from 'axios';
import TagField from 'Components/Forms/TagField';
import MistakeFor from 'Forms/Mistakes/MistakeFor';
import React, {useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import route from 'ziggy-js';

export const validationSchema = Yup.object().shape({
  title: Yup.string().required('Please provide a title'),
  description: Yup.string().required('Please provide a concise description of the mistake'),
});

/**
 * @param {string} entityType
 * @param {string|number} entityId
 * @returns {JSX.Element}
 * @constructor
 */

export const FormFields = ({entityType, entity}) => {
  const requestStarted = useRef(false);
  const [tags, setTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(false);

  useEffect(() => {
    if (!requestStarted.current) {
      setLoadingTags(true);
      axios.get(route('tags.search', {
        type: 'mistake',
      })).then(({data}) => setTags(data))
        .finally(() => setLoadingTags(false));
    }
  }, []);

  return (
    <>
      <div>
        <div className="text-gray-700 font-medium text-sm">Mistake For</div>
        <div className="p-2 bg-gray-100 rounded">
          <MistakeFor entityType={entityType} entity={entity}/>
        </div>
      </div>

      <VerticalFieldWrapper name="title" label="Title">
        <TextField/>
      </VerticalFieldWrapper>

      <VerticalFieldWrapper name="description" label="Description">
        <TextareaField/>
      </VerticalFieldWrapper>

      {!!tags?.length &&
        <VerticalFieldWrapper label="Tags" name="tags" as="div">
          <TagField tags={tags}/>
        </VerticalFieldWrapper>
      }
    </>
  );
};