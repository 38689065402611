import {mdiMagnify, mdiPlus} from '@mdi/js';
import {Icon} from '@mdi/react';
import Lesson from 'Components/Lessons/Lesson';
import React from 'react';

const SearchTypeRow = ({prefixes: propPrefixes = [], type}) => {
  const prefixes = Array.isArray(propPrefixes) ? propPrefixes : [propPrefixes];
  return (
    <tr className="even:bg-lime-50 odd:bg-lime-100 align-top">
      <td className="px-2">{prefixes.map((prefix, index) =>
        <>
          <span className="font-medium">{prefix}:</span>
          {index < prefixes.length - 1 && <span className="text-xs text-gray-700"> or </span>}
        </>,
      )}</td>
      <td className="px-2 border-l border-white">{type}</td>
    </tr>
  );
};

const AdvancedSearch = ({navToLesson} = {}) => {

  return (
    <Lesson
      title="Advanced Search"
      subtitle="A powerful tool for finding work orders"
      sideClassName="bg-gradient-to-t from-lime-500 to-transparent"
      side={
        <div className="relative w-20 h-20 p-2 rounded-full bg-lime-200">
          <Icon path={mdiMagnify} className="text-lime-800"/>
          <Icon path={mdiPlus} className="absolute w-10 text-white top-2 right-2 drop-shadow-lg"/>
        </div>
      }
    >
      <div className="space-y-2">
        <section>
          <h3 className="font-medium">What is Advanced Search?</h3>
          <p className="text-sm text-gray-700">
            Advanced Search is a powerful tool for finding work orders based on the information you enter
          </p>
        </section>
        <section>
          <h3 className="font-medium">How do I use it?</h3>
          <p className="text-sm text-gray-700">
            To use Advanced Search, simply type the information you want to search for into the search bar, prepended
            with the type of information you want to search for. For example, if you want to search for work orders
            for a specific customer, you would type 'customer:customer-name' into the search bar. You can also search
          </p>
          <div className="mt-1 italic text-xs text-gray-700">Note: Searches containing multiple words should be entered in quotes
            eg.&nbsp;customer:"Multiple&nbsp;Word&nbsp;Customer&nbsp;Name"
          </div>
        </section>
        <section>
          <h3 className="font-medium">What can I search for?</h3>
          <p className="text-sm text-gray-700">
            Below is a list of all the search types you can use in Advanced Search. You can also search for multiple
            types of information at once, just separate them with a space
          </p>
          <table className="mt-2 rounded overflow-hidden text-sm w-full">
            <thead>
            <tr className="px-2 bg-lime-300">
              <th className="px-2">Prefix</th>
              <th className="px-2 border-l border-white">Search Type</th>
            </tr>
            </thead>
            <tbody>
            <SearchTypeRow prefixes="customer" type="Orders with a specific customer"/>
            <SearchTypeRow prefixes="location" type="Orders stored at a specified location"/>
            <SearchTypeRow prefixes="tag" type="Orders which have the specified tag"/>
            <SearchTypeRow prefixes={['tag', 'magic']} type={<>
              Orders which have the specified magic tag<br/>
              (see <a href="#" onClick={() => navToLesson('magicTags')} className="text-blue-500 underline">lesson</a> for more info)
            </>}/>
            </tbody>
          </table>
        </section>
      </div>
    </Lesson>
  );
};

Object.assign(AdvancedSearch, {
  lessonName: 'Advanced Search',
  lessonKey: 'advancedSearch',
  modalSize: '2xl',
  group: 'Filtering',
  keywords: ['search', 'filter', 'find', 'advanced', 'prefix', 'parameter'],
});


export default AdvancedSearch;