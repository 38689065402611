import {mdiCog} from '@mdi/js';
import {Icon} from '@mdi/react';
import {formatLongDatetime} from '@webfox/webfox-ui';
import React, {useMemo} from 'react';
import {replaceTokens} from 'Support/tokenHelper';

const JobUpdate = ({user, field, type, from, to, updated_at}) => {
  const text = useMemo(() => {
    const tokenComponents = {
      from: <span className="font-medium px-2 py-1 bg-gray-100 text-gray-700 rounded">{from}</span>,
      to: <span className="font-medium px-2 py-1 bg-blue-100 text-blue-700 rounded">{to}</span>,
      user: <span className="font-medium text-indigo-700">{user?.name}</span>,
      field: <span className="font-medium px-2 py-1 bg-orange-100 text-orange-700 rounded">{field}</span>,
    };

    return replaceTokens(type?.meta?.text, tokenComponents);
  }, [type, from, to, field]);

  return (
    <div className="flex items-start gap-2">
      {type?.meta?.automatic ? (
        <div className="flex items-center justify-center w-8 h-8 rounded-full bg-amber-500 text-white">
          <Icon path={mdiCog} className="w-6 h-6" spin={2}/>
        </div>
      ) : (
        <img src={user?.avatar} alt={`${user?.name}'s Avatar`} className="w-8 h-8 rounded-full"/>
      )}
      <div className="grow flex flex-col mt-1">
        <div className="whitespace-pre">
          {text}
        </div>
        <div className="self-end text-sm text-gray-500">{formatLongDatetime(updated_at)}</div>
      </div>
    </div>
  );
};

export default JobUpdate;