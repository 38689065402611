export const replaceTokens = (string, tokens = {}, fallbackToken = '') => {
  return string.split(/\{|\}/g).reduce((acc, part, index) => {
    if (!part) return acc;
    if (index % 2) {
      acc.push(tokens[part] ?? fallbackToken);
    } else {
      acc.push(part);
    }
    return acc;
  }, []);
};