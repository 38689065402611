import {mdiHelpCircle} from '@mdi/js';
import {Icon} from '@mdi/react';
import Tippy from '@tippyjs/react';
import AllLessonsModal from 'Components/Lessons/AllLessonsModal';
import React, {useEffect, useState} from 'react';

const Key = ({children}) => (
  <kbd className="text-center inline-block text-sm bg-white text-black rounded-sm px-1 min-w-5">{children}</kbd>
);

const AllLessonsButton = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === 'F1') {
        e.preventDefault();
        setOpen(true);
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <>
      <div className="absolute left-0 top-5 hidden lg:block">
        <Tippy content={
          <div className="flex items-center gap-2">
            <Key>F1</Key>
            <div className="text-xs font-medium">OR</div>
            <div><Key>Alt</Key> + <Key>H</Key></div>
          </div>
        } delay={[1000, 0]} placement="right">
          <button accessKey="h" onClick={() => setOpen(true)}
                  className="fixed p-2 hover:pl-4 duration-100 bg-blue-500 text-white rounded-r opacity-25 hover:opacity-100 shadow">
            <Icon path={mdiHelpCircle} className="w-6"/>
          </button>
        </Tippy>
      </div>
      <AllLessonsModal open={open} onClose={() => setOpen(false)}/>
    </>
  );
};

export default AllLessonsButton;