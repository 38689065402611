import {TextareaField, TextField, VerticalFieldWrapper} from '@webfox/webfox-ui';
import React from 'react';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  reference_num: Yup.string().required('Reference number is required'),

  details: Yup.string().required('Details are required'),
});

export const FormFields = () => (
  <>
    <VerticalFieldWrapper label="Reference Num." name="reference_num">
      <TextField/>
    </VerticalFieldWrapper>

    <VerticalFieldWrapper label="Details" name="details">
      <TextareaField/>
    </VerticalFieldWrapper>
  </>
);