import {Transition} from '@headlessui/react';
import Tippy from '@tippyjs/react';
import cls from 'classnames';
import StatusBadgeOption from 'Components/Forms/SelectOptions/StatusBadgeOption';
import StatusBadge from 'Components/Status/StatusBadge';
import React, {forwardRef, useMemo} from 'react';
import Select from 'react-select';

const StatusViewer = ({currentStatus, statuses, positionKey = 'position', classNameKey = 'classNames', onChange, horizontal, fade}) => {
  const [selecting, setSelecting] = React.useState(false);

  const {current, next, prev} = useMemo(() => {
    return statuses.sort((a, b) =>
      a?.meta[positionKey] - b?.meta[positionKey],
    ).reduce((acc, status) => {
      if (status?.meta[positionKey] > currentStatus?.meta[positionKey]) acc.next.push(status);
      if (status?.meta[positionKey] < currentStatus?.meta[positionKey]) acc.prev.push(status);
      if (status?.meta[positionKey] === currentStatus?.meta[positionKey]) acc.current = status;
      return acc;
    }, {
      current: null,
      next: [],
      prev: [],
    });
  }, [currentStatus, statuses, positionKey]);

  const StatusBadgeComponent = useMemo(() => (
    forwardRef((status, ref) => <StatusBadge ref={ref} label={status?.label} {...!!onChange && {onClick: () => setSelecting(true)}}
                                             className={cls(status?.meta[classNameKey], 'border border-transparent delay-0 group-hover:delay-500 duration-150 group-hover:border-blue-500', !!onChange && 'cursor-pointer')}/>,
    )), [classNameKey, onChange]);

  return (
    <div className={cls('inline-block relative delay-0 duration-150 hover:delay-500 hover:drop-shadow-xl hover:z-50', !selecting && 'group')}>
      {!!prev?.length &&
        <div
          className={cls('absolute flex items-center delay-0 duration-150 group-hover:delay-500 opacity-0 group-hover:opacity-75 pointer-events-none', horizontal ? 'flex-row -translate-x-full -translate-y-1/2 left-0 top-1/2' : 'flex-col -translate-y-full -translate-x-1/2 left-1/2 top-0')}
          style={fade ? (() => {
            const mask = `linear-gradient(to ${horizontal ? 'left' : 'top'}, black, transparent ${horizontal ? 20 : 5}rem)`;
            return {
              WebkitMaskImage: mask,
              maskImage: mask,
            };
          })() : null}
        >
          {prev?.map((status) => (
            <React.Fragment key={status?.value}>
              <StatusBadgeComponent {...status}/>
              <div className={cls(horizontal ? 'w-4 border-t' : 'h-4 border-l', 'border-blue-500')}/>
            </React.Fragment>
          ))}
        </div>
      }
      {!!current &&
        <Tippy content="Click to change status" placement={horizontal ? 'top' : 'right'} delay={[1000, 0]} disabled={!onChange}>
          <StatusBadgeComponent {...current}/>
        </Tippy>
      }
      {!!next?.length &&
        <div
          className={cls('absolute flex items-center delay-0 duration-150 group-hover:delay-500 opacity-0 group-hover:opacity-75 pointer-events-none', horizontal ? 'flex-row translate-x-full -translate-y-1/2 right-0 top-1/2' : 'flex-col translate-y-full -translate-x-1/2 left-1/2 bottom-0')}
          style={fade ? (() => {
            const mask = `linear-gradient(to ${horizontal ? 'right' : 'bottom'}, black, transparent ${horizontal ? 20 : 5}rem)`;
            return {
              WebkitMaskImage: mask,
              maskImage: mask,
            };
          })() : null}
        >
          {next?.map((status) => (
            <React.Fragment key={status?.value}>
              <div className={cls(horizontal ? 'w-4 border-t' : 'h-4 border-l', 'border-blue-500')}/>
              <StatusBadgeComponent {...status}/>
            </React.Fragment>
          ))}
        </div>
      }
      <Transition
        show={selecting}
        className="absolute top-1/2 -left-4 w-max -translate-y-1/2 z-50"
        enter="transition ease-out duration-100 transform opacity-0 scale-95"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform opacity-100 scale-100"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Select
          options={statuses}
          components={{
            Option: StatusBadgeOption,
            SingleValue: StatusBadgeOption,
          }}
          value={currentStatus}
          isSearchable={false}
          styles={{
            valueContainer: (provided) => ({
              ...provided,
              input: {position: 'absolute'},
            }),
            menu: (provided) => ({
              ...provided,
              width: 'max-content',
              minWidth: '100%',
              zIndex: 1000,
            }),
          }}
          onChange={(...props) => {
            onChange(...props);
            setSelecting(false);
          }}
          autoFocus
          defaultMenuIsOpen
          onBlur={() => setSelecting(false)}
          className="z-50"
        />
      </Transition>
    </div>
  );
};

export default StatusViewer;