import {
  mdiAccount,
  mdiAlertCircle,
  mdiArchive,
  mdiBriefcaseAccount,
  mdiCheckboxMarked,
  mdiClipboardList,
  mdiCog,
  mdiCogs,
  mdiConnection,
  mdiDolly,
  mdiFolder,
  mdiFormTextbox,
  mdiHome,
  mdiLogout,
  mdiProgressWrench,
  mdiReceiptText,
  mdiTag,
  mdiTshirtCrew,
} from '@mdi/js';
import route from 'ziggy-js';

export const navItems = (user) => [
  {
    title: 'Dashboard',
    href: route('dashboard'),
    icon: mdiHome,
    active: 'dashboard',
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Work Orders',
    href: route('jobs.index'),
    icon: mdiFolder,
    active: 'jobs.*',
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Pre-Processing Setup',
    icon: mdiProgressWrench,
    active: 'pre-processing.*',
    href: route('pre-processing.assignment'),
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Work Sheets',
    href: route('work-sheets.index'),
    icon: mdiClipboardList,
    active: 'work-sheets.*',
  },
  {
    title: 'Supply Orders',
    href: route('supply-orders.index'),
    icon: mdiDolly,
    active: 'supply-orders.*',
  },
  {
    title: 'Invoices',
    href: route('invoices.index'),
    icon: mdiReceiptText,
    active: 'invoices.*',
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Products',
    href: route('products.index'),
    icon: mdiTshirtCrew,
    active: 'products.*',
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Mistake Register',
    href: route('mistakes.index'),
    icon: mdiAlertCircle,
    active: 'mistakes.*',
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Users',
    href: route('users.index'),
    icon: mdiAccount,
    active: 'users.*',
    filter: () => ['admin'].includes(user.role.value),
  },
  {
    title: 'Settings',
    icon: mdiCog,
    sub: [
      {
        title: 'Processing',
        href: route('processes.index'),
        icon: mdiCogs,
        active: 'processes.index',
        filter: () => ['admin'].includes(user.role.value),
      },
      {
        title: 'Customers',
        href: route('customers.index'),
        icon: mdiBriefcaseAccount,
        active: 'customers.*',
        filter: () => ['admin'].includes(user.role.value),
      },
      {
        title: 'Storage',
        href: route('locations.index'),
        icon: mdiArchive,
        active: 'locations.*',
        filter: () => ['admin'].includes(user.role.value),
      },
      {
        title: 'Tags',
        href: route('tags.index'),
        icon: mdiTag,
        active: 'tags.*',
        filter: () => ['admin'].includes(user.role.value),
      },
      {
        title: 'Common Values',
        href: route('common-entries.index'),
        icon: mdiFormTextbox,
        active: 'common-entries.*',
        filter: () => ['admin'].includes(user.role.value),
      },
      {
        title: 'Default Tasks',
        href: route('defaults.tasks'),
        icon: mdiCheckboxMarked,
        active: 'defaults.*',
        filter: () => ['admin'].includes(user.role.value),
      },
      {
        title: 'Integrations',
        href: route('integrations.index'),
        icon: mdiConnection,
        active: 'integrations.*',
        filter: () => ['admin'].includes(user.role.value),
      },
    ],
  },
  // {
  //   title: 'Settings',
  //   icon: mdiCog,
  // },
].filter(item => typeof item.filter !== 'function' || item.filter());

export const accountMenuItems = [
  {
    title: 'Log out',
    href: route('logout'),
    icon: mdiLogout,
  },
];