import {FieldContext} from '@webfox/webfox-ui';
import React, {useContext} from 'react';

const SubFieldWrapper = ({name, children}) => {
  const {name: parentName} = useContext(FieldContext);

  return (
    <FieldContext.Provider value={{name: `${parentName}.${name}`}}>
      {children}
    </FieldContext.Provider>
  );
};

export default SubFieldWrapper;