import {mdiAutoFix, mdiTag} from '@mdi/js';
import {Icon} from '@mdi/react';
import Lesson from 'Components/Lessons/Lesson';
import React from 'react';

const MagicTags = () => (
  <Lesson
    title="Magic Tags"
    subtitle="A useful tool for filtering work orders"
    side={
      <div className="relative w-20 h-20 p-2 rounded-full bg-blue-200">
        <Icon path={mdiTag} className="text-blue-800"/>
        <Icon path={mdiAutoFix} className="absolute w-10 text-white bottom-3 left-3 drop-shadow-lg"/>
      </div>
    }
  >
    <div className="space-y-2">
      <section>
        <h3 className="font-medium">What are Magic Tags?</h3>
        <p className="text-sm text-gray-700">
          Magic Tags are tags that are automatically applied to work orders based on the information you enter
        </p>
      </section>
      <section>
        <h3 className="font-medium">How are they useful?</h3>
        <p className="text-sm text-gray-700">
          Magic Tags are a great way to filter work orders based on the information you enter. For example, if you
          add a process to a product on a work order, you can filter all work orders that have that process by searching
          for the process name
        </p>
      </section>
      <section>
        <h3 className="font-medium">How do they work?</h3>
        <p className="text-sm text-gray-700">
          Magic! of course! But seriously, when you add a product, process, or other information that may be useful for filtering to a work order, a
          Magic Tag is automatically created for that information. You can then search for that information by typing
          the 'magic:' or 'tag:' followed by the tag name into the search bar
        </p>
      </section>
    </div>
  </Lesson>
);

Object.assign(MagicTags, {
  lessonName: 'Magic Tags',
  lessonKey: 'magicTags',
  modalSize: '2xl',
  group: 'Filtering',
  keywords: ['magic', 'tags', 'filter', 'search', 'auto', 'automatic'],
});

export default MagicTags;