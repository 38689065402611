import {usePage} from '@inertiajs/inertia-react';
import {mdiBellRing, mdiMessage} from '@mdi/js';
import {Icon} from '@mdi/react';
import {axios} from '@webfox/webfox-ui';
import ViewMessagesPanel from 'Forms/Messages/ViewMessagesPanel';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import route from 'ziggy-js';

const MessageMenu = (factory, deps) => {
  const [open, setOpen] = useState(false);
  const [threads, setThreads] = useState([]);
  const [erroredAt, setErroredAt] = useState(null);

  const fetchThreads = useCallback(() => {
    axios.get(route('message-threads.index')).then(({data}) => {
      setThreads(data);
      setErroredAt(null);
    }).catch(() => {
      setErroredAt(new Date());
    });
  }, [setThreads]);

  useEffect(() => {
    const refresh = setTimeout(fetchThreads, 60000);

    return () => clearTimeout(refresh);
  }, [threads, erroredAt]);

  useEffect(() => {
    fetchThreads();
  }, [usePage()]);

  const hasUnread = useMemo(() => threads.some(thread => new Date(thread?.last_message_at) > new Date(thread?.current_thread_user?.last_read_at)), [threads]);
  const hasUnreadPing = useMemo(() => threads?.some(thread => new Date(thread?.last_ping_message?.created_at) > new Date(thread?.current_thread_user?.last_read_at)), [threads]);

  return (
    <>
      <div className="fixed bottom-0 right-0 flex flex-col items-center gap-5 shadow-xl">
        {hasUnreadPing && <Icon path={mdiBellRing} className="text-primary animate-ping w-6"/>}
        <button type="button" onClick={() => setOpen(true)}
                className="relative flex items-center gap-1 rounded-tl-xl p-2 bg-primary text-white font-medium duration-150 origin-bottom-right hover:scale-105">
          <Icon path={mdiMessage} className="w-6 z-10"/>
          <div>Messages</div>
          {hasUnread && <div className="absolute z-10 -top-1 -left-1 w-4 h-4 bg-blue-500 border border-white rounded-full"/>}
        </button>
        <ViewMessagesPanel
          open={open} onClose={() => setOpen(false)}
          threads={threads}
          erroredAt={erroredAt}
          onUpdate={fetchThreads}
        />
      </div>
    </>
  );
};

export default MessageMenu;