const generateCheckListTree = (nodes) => {
  const _buildSubTree = (node, parentNode, depth) => {
    const treeNode = {
      id: node.id,
      data: node,
      parent_id: parentNode?.id,
      parent: parentNode,
      depth,
    };

    treeNode.children = node?.sub_list?.all_items?.map((child) => _buildSubTree(child, treeNode, depth + 1)) || [];

    return treeNode;
  };

  return nodes?.map(node => _buildSubTree(node, null, 1));
};

export default generateCheckListTree;