import {mdiExclamation, mdiHelp} from '@mdi/js';
import {Icon} from '@mdi/react';
import {Modal} from '@webfox/webfox-ui';
import cls from 'classnames';
import getLessons from 'Components/Lessons/getLessons';
import React, {useCallback, useEffect, useState} from 'react';

const LessonModal = ({open, onClose, lessonKey}) => {
  const [lesson, setLesson] = useState(getLessons(lessonKey)[0]);
  const [hasNavigated, setHasNavigated] = useState(false);

  const changeLesson = useCallback((key, fromNav = false) => {
    setLesson(getLessons(key)[0]);
    if (fromNav) {
      setHasNavigated(true);
    }
  }, []);

  useEffect(() => {
    changeLesson(lessonKey);
    setHasNavigated(false);
  }, [lessonKey]);

  const close = useCallback(() => {
    onClose();
    changeLesson(lessonKey);
  }, [onClose, lessonKey]);

  const Lesson = lesson?.Lesson ?? null;

  return (
    <Modal open={open} onClose={close} size={lesson?.modalSize}>
      <Modal.Content withoutPadding>
        {Lesson ? <Lesson navToLesson={(key) => changeLesson(key, true)}/> :
          <div className="p-10 flex flex-col items-center justify-center gap-2 bg-gradient-to-t from-red-500 to-transparent">
            <Icon path={mdiExclamation} className="text-white w-20"/>
            <div className="text-white text-xl font-medium">Lesson Not Found</div>
            {hasNavigated &&
              <button type="button" onClick={() => changeLesson(lessonKey)}
                      className="px-2 py-1 bg-gradient-to-t from-transparent via-red-300 to-white text-red-800 font-medium rounded shadow-xl duration-150 hover:scale-110">
                Go Back
              </button>
            }
            <div className="absolute inset-0 h-full mix-blend-color-burn noise-filter pointer-events-none"/>
          </div>
        }
        <div className="absolute top-2 right-2">
          <Modal.CloseButton onClose={close}/>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export const LessonButton = ({lessonKey, className = 'bg-blue-200 text-blue-800'}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button type="button" onClick={() => setOpen(true)}
              className={cls('p-1 duration-100 rounded-full hover:scale-125 hover:shadow-lg', className)}>
        <Icon path={mdiHelp} className="w-4"/>
      </button>
      <LessonModal open={open} onClose={() => setOpen(false)} lessonKey={lessonKey}/>
    </>
  );
};

export default LessonModal;