import BasePing from 'Components/Pings/BasePing';
import ViewJobModal from 'Forms/Jobs/ViewJobModal';
import React, {useState} from 'react';

const JobPing = ({id}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <BasePing action={() => setOpen(true)}>
        <div className="flex-1 p-1 text-left">
          <div className="font-bold">Work Order #{id}</div>
          <div className="text-xs">This work order requires your attention</div>
        </div>
      </BasePing>
      <ViewJobModal open={open} onClose={() => setOpen(false)} jobId={id}/>
    </>
  );
};

export default JobPing;