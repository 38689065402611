import {Popover} from '@headlessui/react';
import {mdiLoading} from '@mdi/js';
import {Icon} from '@mdi/react';
import {FieldContext, Formik, PrimaryButton, useInertiaForm} from '@webfox/webfox-ui';
import axios from 'axios';
import cls from 'classnames';
import TreeField from 'Components/Forms/TreeField';
import {Form} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import route from 'ziggy-js';

const LocationForm = ({open, close, job, location, onLocationChange}) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [locations, setLocations] = useState([]);

  const formikProps = useInertiaForm({
    defaultValues: {
      location_id: location?.id,
    },
    submit: ({reset, data}) => {
      setSaving(true);
        axios.put(route('jobs.update-location', job), data).then(({data}) => {
        reset();
        onLocationChange(data);
        close();
      }).finally(() => setSaving(false));
    },
  });

  const loadLocations = useCallback(() => {
    setLoading(true);
      axios.get(route('locations.search', {
          branch: job?.branch_id,
      })).then(({data}) => {
      setLocations(data);
    }).finally(() => setLoading(false));
  }, [job?.branch_id]);

  useEffect(() => {
    if (open) {
      loadLocations();
    }
  }, [open]);

  return (
    loading ?
      <div className="flex flex-col items-center justify-center gap-1 w-full">
        <Icon path={mdiLoading} className="w-8 animate-spin"/>
        <div>Loading Locations</div>
      </div>
      : (
        <Formik {...formikProps}>
          {() => (
            <Form className="relative">
              <div className={cls('w-full duration-150', saving && 'blur')}>
                <FieldContext.Provider value={{name: 'location_id'}}>
                  <TreeField nodes={locations} clearable/>
                </FieldContext.Provider>
                <PrimaryButton type="submit" className="mt-2 w-full">Save</PrimaryButton>
              </div>
              {saving &&
                <div className="absolute flex items-center inset-0">
                  <div className="flex flex-col items-center justify-center gap-1 w-full">
                    <Icon path={mdiLoading} className="w-8 animate-spin"/>
                    <div>Saving...</div>
                  </div>
                </div>
              }
            </Form>
          )}
        </Formik>
      )
  );
};

const LocationPopover = ({job, location, onLocationChange}) => {

  return (
    <Popover className="relative">
      <Popover.Button className={cls(!location && 'px-2 py-1 rounded duration-100 bg-gray-200 hover:bg-gray-300 text-gray-700')}>
        {
          location?.name || 'Assign Storage'
        }
      </Popover.Button>
      <Popover.Panel
          className="absolute bottom-0 -left-8 translate-y-full z-20 min-w-96 w-fit p-2 mt-2 bg-white border rounded shadow-lg whitespace-nowrap">
        {({open, close}) => (
            <LocationForm open={open} close={close} job={job} location={location} onLocationChange={onLocationChange}/>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default LocationPopover;