import cls from 'classnames';
import React, {useMemo} from 'react';

export const LegendTypes = {
  TOP: 'top',
  BOTTOM: 'bottom',
  OVERLAY: 'overlay',
  NONE: 'none',
};

const PortionBar = ({
  segments,
  classNames: {
    container: containerClasses = 'flex flex-col items-stretch gap-1',
    barContainer: barContainerClasses = 'flex items-center gap-2',
    track: trackClasses = 'grow h-8 flex items-stretch overflow-hidden rounded',
    segment: segmentClasses = 'py-2 duration-500 overflow-hidden',
    segmentText: segmentTextClasses = 'flex h-full items-center justify-center gap-1 text-sm font-medium',
    legendContainer: legendContainerClasses = 'grid grid-cols-3 gap-1',
    legendSubContainer: legendSubContainerClasses = 'flex items-center gap-1',
    legendText: legendTextClasses = 'text-gray-900',
    legendBox: legendBoxClasses = 'w-5 h-5 rounded-sm',
    quantityText: quantityTextClasses = 'font-bold',
    totalText: totalTextClasses = 'font-medium',
  } = {},
  showQuantities,
  showTotal,
  legendType = LegendTypes.OVERLAY,
}) => {
  const total = useMemo(() => (
    segments.reduce((carry, segment) => carry + segment.value, 0)
  ), [segments]);

  return (
    <div className={containerClasses}>
      {[LegendTypes.TOP, LegendTypes.BOTTOM].includes(legendType) &&
        <div className={cls(legendContainerClasses, legendType === LegendTypes.BOTTOM && 'order-2')}>
          {segments.map(segment => (
            <div className={legendSubContainerClasses}>
              <div className={cls(legendBoxClasses, segment?.className)} style={segment.style || null}/>
              {!!showQuantities && <div className={quantityTextClasses}>{segment.value}</div>}
              <div className={legendTextClasses}>{segment.name}</div>
            </div>
          ))}
        </div>
      }
      <div className={barContainerClasses}>
        <div className={trackClasses}>
          {segments.map(segment => (
            !!segment.value ?
              <div className={cls(segmentClasses, segment?.className)} style={{
                width: `${(segment.value / total) * 100}%`,
                ...segment?.style,
              }}>
                {legendType === LegendTypes.OVERLAY &&
                  <div className={cls(segmentTextClasses, segment?.textClassName || 'text-white')}>
                    {!!showQuantities && <div className={quantityTextClasses}>{segment.value}</div>}
                    <div>{segment.name}</div>
                  </div>
                }
              </div>
              : null
          ))}
        </div>
        {!!showTotal && <div className={totalTextClasses}>{total}</div>}
      </div>
    </div>
  );
};

export default PortionBar;