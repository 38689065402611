import {mdiCheckCircle, mdiChevronRight, mdiMinus} from '@mdi/js';
import {Icon} from '@mdi/react';
import {FieldContext, Tree, useTree} from '@webfox/webfox-ui';
import cls from 'classnames';
import {useField} from 'formik';
import React, {useContext, useEffect, useMemo} from 'react';

const FieldNode = ({node, expanded, onChangeExpand, shouldExpand, onClick, labelKey, selected}) => (
  <div className="flex items-center my-1">
    {shouldExpand ?
      <button
        type="button"
        onClick={() => onChangeExpand(!expanded)}
        className="p-1 m-1 hover:bg-gray-100 rounded"
      >
        <Icon path={mdiChevronRight} className={cls('w-6 duration-150', expanded && 'rotate-90')}/>
      </button>
      :
      <div className="p-1 m-1">
        <Icon path={mdiMinus} className="w-6 text-gray-200"/>
      </div>
    }
    <button
      type="button"
      onClick={onClick}
      className={cls('flex items-center justify-between grow px-3 py-2 text-gray-900 font-medium rounded shadow rounded border-green-500 duration-150', selected ? 'bg-green-50 border-2' : 'bg-white')}
    >
      {node.data[labelKey]}
      {selected && <Icon path={mdiCheckCircle} className="w-5 text-green-500"/>}
    </button>
  </div>
);

const TreeField = ({nodes: originalNodes, labelKey = 'name', valueKey = 'id', clearable, options = {}, components = {}, ...props}) => {
  const tree = useTree(originalNodes, [originalNodes], options);
  const {name} = useContext(FieldContext);
  const [field, {initialValue}, {setValue, setTouched}] = useField(name);

  const Node = useMemo(() => {
    return components?.Node || FieldNode;
  }, [components]);

  useEffect(() => {
    if (initialValue) {
      tree.expandToNode(initialValue);
    }
  }, [initialValue]);

  return (
    <Tree
      tree={tree}
      dndOptions={{
          ownProvider: true,
      }}
      components={{
        Node: ({node, ...props}) => {
          const selected = field.value === node.data[valueKey];

          return (
            <Node
              node={node}
              labelKey={labelKey}
              selected={selected}
              onClick={() => {
                setValue(clearable && selected ? null : node[valueKey]);
                setTouched(true);
              }}
              {...props}
            />
          );
        },
        ...components,
      }}
      {...props}
    />
  );
};

export default TreeField;