import {axios, dateToLaravel} from '@webfox/webfox-ui';
import cls from 'classnames';
import DateRangeFilter from 'Components/Forms/Filters/DateRangeFilter';
import Paginator from 'Components/Pagination/Paginator';
import JobUpdate from 'Forms/Jobs/Partials/JobUpdate';
import React, {useCallback, useEffect, useState} from 'react';
import route from 'ziggy-js';

const initialFilterState = {
  startDate: null,
  endDate: null,
  ascending: false,
};

const JobUpdateList = ({jobId, initialData = {}}) => {
  const [loading, setLoading] = useState(false);
  const [{data: updates, ...pagination}, setData] = useState(initialData);
  const [filters, setFilters] = useState(initialFilterState);

  const loadUpdates = useCallback((page = null) => {
    setLoading(true);
    axios.get(route('jobs.updates.data', {
      job: jobId,
      page: page ?? pagination?.current_page,
      asc: filters.ascending,
      before: dateToLaravel(filters.endDate),
      after: dateToLaravel(filters.startDate),
    })).then(({data}) => {
      setData(data);
    }).finally(() => setLoading(false));
  }, [jobId, filters, pagination, filters]);

  useEffect(() => {
    if (jobId) {
      setData({});
      setFilters({...initialFilterState});
    }
  }, [jobId]);

  useEffect(() => {
    if (jobId) {
      loadUpdates(1);
    }
  }, [filters]);


  return (
    <div className="space-y-6">
      <div className="flex items-center gap-5">
        <div className="flex items-center gap-1">
          <div>Order by:</div>
          <button type="button" onClick={() => setFilters(prevFilters => ({...filters, ascending: !prevFilters?.ascending}))}
                  className="flex items-center gap-1 px-2 py-1 duration-100 bg-white hover:bg-gray-50 border rounded">
            <span className={cls(filters.ascending ? 'text-gray-500' : 'font-medium')}>Latest First</span>
            <span>/</span>
            <span className={cls(filters.ascending ? 'font-medium' : 'text-gray-500')}>Oldest First</span>
          </button>
        </div>
        <div className="flex items-center gap-1">
          <dev>Update Date:</dev>
          <DateRangeFilter
            startDate={filters.startDate}
            endDate={filters.endDate}
            onChange={(dateRange) => setFilters(prevFilters => ({...prevFilters, ...dateRange}))}
          />
        </div>
      </div>
      {!!updates ?
        <div className={cls('relative flex flex-col gap-5 duration-300', loading && 'grayscale blur')}>
          <div className="absolute my-2 left-7 top-0 bottom-0 border border-indigo-500"/>
          {updates.map((update) => (
            <div key={update.id} className="p-3 bg-white rounded border shadow-sm z-10">
              <JobUpdate {...update}/>
            </div>
          ))}
        </div>
        :
        <div className="flex flex-col items-center font-medium text-lg text-gray-500">Loading Data...</div>
      }
      <div className="flex items-center justify-end">
        <div className="w-96">
          <Paginator pagination={pagination} onChange={loadUpdates}/>
        </div>
      </div>
    </div>
  );
};

export default JobUpdateList;