import {mdiChevronLeft, mdiChevronRight} from '@mdi/js';
import {Icon} from '@mdi/react';
import React, {useEffect, useMemo, useRef} from 'react';

const Paginator = ({pagination: {from, to, current_page, last_page, total}, onChange}) => {
  const pageInput = useRef();

  const [hasNext, hasPrev] = useMemo(() =>
      [current_page < last_page, current_page > 1]
    , [current_page, last_page]);

  useEffect(() => {
    if (pageInput?.current) {
      const onInputChange = ({target}) => {
        if (!!target.value) {
          const page = Math.max(Math.min(target.value.replace(/[^0-9]/g, ''), last_page), 1);

          if (page !== current_page) {
            typeof onChange === 'function' && onChange(page);
          } else {
            target.value = page;
          }
        }
      };
      pageInput.current.addEventListener('change', onInputChange);
      return () => pageInput?.current?.removeEventListener('change', onInputChange);
    }
  }, [last_page, onChange]);

  useEffect(() => {
    if (pageInput?.current) {
      pageInput.current.value = current_page;
    }
  }, [current_page]);

  return (
    <div className="flex flex-col items-center gap-2 text-gray-700">
      <div className="grow grid grid-cols-3 items-stretch divide-x bg-white border rounded-lg shadow-sm overflow-hidden">
        <button type="button" disabled={!hasPrev} onClick={typeof onChange === 'function' ? () => onChange(current_page - 1) : null}
                className="flex items-center justify-center gap-1 p-2 enabled:hover:bg-gray-50">
          {
            !!hasPrev &&
            <>
              <Icon path={mdiChevronLeft} className="w-6"/>
              <span>Previous</span>
            </>
          }
        </button>
        <div className="flex flex-col items-center p-2">
          <input type="number" ref={pageInput} defaultValue={current_page} onClick={({target}) => target.value = ''} onBlur={({target}) => {
            if (!target?.value) {
              target.value = current_page;
            }
          }} className="w-full text-xl font-medium text-center p-0 border-0 spinners-none focus:outline-none focus:ring-0"/>
          <span className="text-xs font-medium text-gray-500">of {last_page}</span>
        </div>
        <button type="button" onClick={typeof onChange === 'function' ? () => onChange(current_page + 1) : null} disabled={!hasNext}
                className="flex items-center justify-center gap-1 p-2 enabled:hover:bg-gray-50">
          {
            !!hasNext &&
            <>
              <span>Next</span>
              <Icon path={mdiChevronRight} className="w-6"/>
            </>
          }
        </button>
      </div>
      <div>
        <span>Showing <b>{from}</b> to <b>{to}</b> of <b>{total}</b> results</span>
      </div>
    </div>
  );
};

export default Paginator;