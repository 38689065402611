import {usePage} from '@inertiajs/inertia-react';
import React, {useEffect, useState} from 'react';

const useDevMode = () => {
  const [devMode, setDevMode] = useState(window.localStorage.getItem('devMode') === 'true');
  const page = usePage();

  useEffect(() => {
    const onDevModeChange = (event) => {
      const devMode = window.localStorage.getItem('devMode') === 'true';
      setDevMode(devMode);
    };

    const onStorage = (event) => {
      if (event.key === 'devMode') {
        onDevModeChange(event);
      }
    };

    window.addEventListener('devModeChanged', onDevModeChange);
    window.addEventListener('storage', onStorage);

    return () => {
      window.removeEventListener('devModeChanged', onDevModeChange);
      window.removeEventListener('storage', onStorage);
    };
  }, []);

  return [
    !page?.props?.application?.production && devMode,
    (set = null) => {
      const devMode = set === null ? !(window.localStorage.getItem('devMode') === 'true') : set;

      window.localStorage.setItem('devMode', devMode?.toString());

      window.dispatchEvent(new CustomEvent('devModeChanged', {detail: {devMode: devMode}}));
      alert('Dev mode has been ' + (devMode ? 'enabled' : 'disabled'));
    },
  ];
};

export default useDevMode;