import StatusBadge from 'Components/Status/StatusBadge';
import React from 'react';
import tailwindTheme from 'tailwind-theme';

const StatusBadgeOption = ({innerProps, data}) => (
  <div className="p-2 hover:bg-gray-100 cursor-pointer" {...innerProps}>
    <div className="pointer-events-none">
      <StatusBadge {...data} className={data?.meta?.classNames}/>
    </div>
  </div>
);

export const multiSelectStyles = {
  multiValue: () => ({
    display: 'flex',
    borderWidth: tailwindTheme.borderWidth.DEFAULT,
    borderColor: tailwindTheme.borderColor.gray[200],
    borderRadius: tailwindTheme.borderRadius.DEFAULT,
    marginRight: tailwindTheme.margin[1],
    boxShadow: tailwindTheme.boxShadow.sm,
  }),
};

export default StatusBadgeOption;