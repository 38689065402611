import {axios, HorizontalFieldWrapper, SelectField, TextField} from '@webfox/webfox-ui';
import UserOption from 'Components/Forms/SelectOptions/UserOption';
import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import route from 'ziggy-js';

export const validationSchema = Yup.object().shape({
  users: Yup.array().of(Yup.number()).min(1).required(),
});

const UserSelectField = (props) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get(route('users.search', {withoutCurrentUser: 1})).then(({data}) => {
      setUsers(data);
    });
  }, []);

  return (
    <SelectField
      isLoading={users.length === 0}
      isMulti
      isClearable
      components={{
        Option: UserOption,
      }}
      options={users}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      labelKey="name"
      valueKey="id"
    />
  );
};

export const MessageThreadForm = () => (
  <>
    <HorizontalFieldWrapper label="Name" name="name">
      <TextField/>
    </HorizontalFieldWrapper>

    <HorizontalFieldWrapper label="Users" name="users">
      <UserSelectField/>
    </HorizontalFieldWrapper>
  </>
);