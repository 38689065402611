import React, {useMemo} from 'react';

const ThreadIcon = ({thread}) => {
  const isGroup = useMemo(() => thread?.users?.length > 1, [thread?.users]);

  return (
    <div className="relative w-12 h-12">
      {isGroup ?
        <>
          <img src={thread?.users[0]?.avatar} alt={`${thread?.users[0].name}'s Avatar`} className="absolute w-8 top-0 right-0 rounded-full"/>
          <img src={thread?.users[1]?.avatar} alt={`${thread?.users[1].name}'s Avatar`} className="absolute w-8 bottom-0 left-0 rounded-full"/>
        </>
        :
        <img src={thread?.users[0]?.avatar} alt={`${thread?.users[0].name}'s Avatar`} className="w-12 rounded-full"/>
      }
    </div>
  );
};

export default ThreadIcon;