import {Form, Formik, Modal, PrimaryButton, SecondaryButton, useInertiaForm} from '@webfox/webfox-ui';
import {FormFields, validationSchema} from 'Forms/Mistakes/Form';
import route from 'ziggy-js';

const AddMistakeModal = ({open, onClose, entity, entityType}) => {
  const formikProps = useInertiaForm({
    defaultValues: {
      title: '',
      description: '',
      tags: [],
    },
    submit: ({post, reset}) => post(route('mistakes.store', {entity: entity?.id, entity_type: entityType}), {
      onSuccess: () => {
        onClose();
        reset();
      },
    }),
    validationSchema,
  });

  return (
    <Formik {...formikProps}>
      {({isValid}) => (
        <Modal open={open} onClose={onClose} size="7xl">
          <Modal.Content as={Form}>
            <Modal.Header title="Register Mistake"/>
            <div className="space-y-6">
              <FormFields entityType={entityType} entity={entity}/>
            </div>
            <Modal.Actions>
              <SecondaryButton type="button" onClick={onClose}>Cancel</SecondaryButton>
              <PrimaryButton type="submit" disabled={!isValid}>Create</PrimaryButton>
            </Modal.Actions>
          </Modal.Content>
        </Modal>
      )}
    </Formik>
  );
};

export default AddMistakeModal;