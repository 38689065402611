import {mdiHelp, mdiMagnify} from '@mdi/js';
import {Icon} from '@mdi/react';
import {Modal, useStateDebounced} from '@webfox/webfox-ui';
import cls from 'classnames';
import CollapseContainer from 'Components/CollapseContainer';
import getLessons from 'Components/Lessons/getLessons';
import Logo from 'Images/logo.png';
import React, {useCallback, useMemo, useRef, useState} from 'react';
import useUser from 'Support/Hooks/useUser';

const AllLessonsModal = ({open, onClose}) => {
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [search, setSearch] = useStateDebounced('');
  const [searching, setSearching] = useState(false);
  const searchRef = useRef();
  const user = useUser();

  const filteredLessonGroups = useMemo(() => {
    const searchString = search.toLowerCase();
    return getLessons().reduce((groups, lesson) => {
      if (
        (!lesson.roleRestrictions?.length || lesson.roleRestrictions?.includes(user.role.value)) &&
        (!searchString || lesson.name.toLowerCase().includes(searchString) || lesson?.keywords?.some((keyword) => keyword.toLowerCase().includes(searchString)))
      ) {
        const lessonGroup = lesson?.group ?? 'Other';

        if (!groups[lessonGroup]) {
          groups[lessonGroup] = [];
        }
        groups[lessonGroup].push(lesson);
      }
      return groups;
    }, {});
  }, [user, search]);

  const startSearch = useCallback(() => {
    setSearching(true);
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  const close = useCallback(() => {
    setSelectedLesson(null);
    setSearch('');
    setSearching(false);
    onClose();
  }, [onClose]);

  const navToLesson = useCallback((lessonKey) => {
    const lessons = getLessons(lessonKey);
    if (!!lessons.length) {
      setSelectedLesson(lessons[0]);
    }
  }, []);

  const Lesson = selectedLesson?.Lesson ?? null;
  const lessonEntries = Object.entries(filteredLessonGroups);

  return (
    <Modal open={open} onClose={close} size="5xl">
      <Modal.Content withoutPadding className="flex min-h-[50vh] max-h-[60vh]">
        <CollapseContainer show={true}>
          <div className="flex flex-col p-2 whitespace-nowrap h-full max-h-full min-w-52">
            <div className={cls('flex items-center border-b mb-2 duration-150', searching && 'border-transparent')}>
              <div className="grow relative">
                <div className="font-medium text-xl px-5 py-2">Lessons</div>
                <div className={cls('absolute inset-0 bg-white origin-right duration-150', searching ? 'scale-x-100' : 'scale-x-0')}>
                  <input
                    ref={searchRef}
                    type="text"
                    onChange={({target: {value}}) => setSearch(value)}
                    onBlur={({target: {value}}) => !value && setSearching(false)}
                    className="w-full h-full border-0 border-b border-gray-200 focus:border-b-blue-500 focus:ring-0 focus:outline-none"
                  />
                </div>
              </div>
              <button type="button" onClick={startSearch} className="w-6 text-gray-300">
                <Icon path={mdiMagnify}/>
              </button>
            </div>
            <div className="grow flex flex-col gap-1 min-h-0 overflow-y-auto">
              {!!lessonEntries?.length ?
                lessonEntries.map(([name, group]) => (
                  <div key={name} className="flex flex-col gap-1">
                    <div className="font-medium text-sm text-gray-700 px-1 border-b mb-1">{name}</div>
                    <div className="flex flex-col gap-1">
                      {group.map((lesson) => (
                        <div className="relative flex group group-hover">
                          <button key={lesson.key} onClick={() => setSelectedLesson(lesson)}
                                  className={cls('grow flex items-center px-5 py-2 duration-100 rounded', selectedLesson?.key === lesson.key ? 'bg-blue-200 text-blue-800 hover:bg-blue-100' : 'hover:bg-gray-100')}>
                            {lesson.name}
                          </button>
                          {!!lesson?.keywords?.length &&
                          <div
                            className="absolute flex items-center gap-1 flex-wrap p-1 w-full bottom-0 translate-y-full rounded origin-top duration-150 bg-blue-300/25 border border-blue-500 backdrop-blur opacity-0 scale-75 group-hover:shadow-lg group-hover:scale-100 group-hover:opacity-100 group-hover:delay-1000 pointer-events-none z-50">
                            {lesson?.keywords?.map(word => (
                              <div key={word} className="px-2 py-1 text-xs text-blue-800 bg-blue-200 rounded">{word}</div>
                            ))}
                          </div>
                          }
                        </div>
                      ))}
                    </div>
                  </div>
                )) :
                <div className="w-full text-center p-2 text-gray-400">
                  No Lessons Found
                </div>
              }
            </div>
          </div>
        </CollapseContainer>
        <div className="grow flex">
          {Lesson ?
            <Lesson navToLesson={navToLesson}/>
            :
            <div className="grow relative flex flex-col justify-center items-center p-5 bg-gradient-to-t from-blue-500 to-transparent">
              <Icon path={mdiHelp} className="w-40 text-white drop-shadow-lg" style={{transform: 'rotate(25deg)'}}/>
              <div className="flex flex-col items-center gap-2">
                <div className="text-center text-4xl font-semibold text-white drop-shadow-lg">Welcome To<br/>The Help Center</div>
                <div className="contents font-medium">
                  <div className="text-lg text-blue-100">
                    Here you can find lessons on how to use the various features of the app
                  </div>
                  <div className="px-2 pt-1 pb-5 -mb-4 bg-gradient-to-b from-blue-200 to-transparent via-blue-300 rounded-t text-blue-800">
                    Select a lesson from the list on the left to get started
                  </div>
                </div>
              </div>
              <img src={Logo} alt="HB Embroidery Logo" className="absolute bottom-5 right-5 w-20"/>
              <div className="absolute inset-0 h-full w-full mix-blend-color-burn noise-filter"
                   style={{filter: 'grayscale(1) contrast(150%) brightness(50%)'}}/>
            </div>
          }
        </div>
        <div className="absolute top-2 right-2">
          <Modal.CloseButton onClose={close}/>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AllLessonsModal;