import {Icon} from '@mdi/react';
import Tippy from '@tippyjs/react';
import cls from 'classnames';
import React from 'react';

const Tag = ({onClick, endButton, ...tag}) => {
  const Element = onClick ? 'button' : 'div';

  return (
    <Tippy content={tag?.description} disabled={!tag?.description}>
      <Element {...!!onClick && {onClick: () => onClick(tag)}}
               className={cls('relative flex items-stretch w-fit select-none overflow-hidden', !!onClick && 'duration-150 hover:drop-shadow hover:scale-105')}>
        <div className="self-center pl-1 py-1 rounded-l-full bg-gray-200" style={tag?.style}>
          <div className="w-1 h-1 rounded-full bg-white"/>
        </div>
        <div
          className={cls('flex items-center gap-2 px-2 py-1 text-sm font-medium text-black bg-gray-200 whitespace-nowrap rounded-r', tag?.name && 'rounded-l')}
          style={tag?.style}>
          <div>{tag?.name}</div>
          {endButton}
        </div>
      </Element>
    </Tippy>
  );
};

Tag.Button = ({icon, ...props}) => (
  <button type="button" className="duration-150 hover:scale-125 hover:text-white hover:drop-shadow" {...props}>
    <Icon path={icon} className="w-4"/>
  </button>
);

export default Tag;