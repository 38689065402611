import {Tab, Transition} from '@headlessui/react';
import {mdiChevronDown} from '@mdi/js';
import {Icon} from '@mdi/react';
import {axios, Modal} from '@webfox/webfox-ui';
import cls from 'classnames';
import CommentSection from 'Components/CommentSection';
import PortionBar, {LegendTypes} from 'Components/PortionBar';
import Product from 'Components/Product';
import ProgressBar from 'Components/ProgressBar';
import JobFilesList from 'Forms/Jobs/Partials/JobFilesList';
import TaskLists from 'Forms/Jobs/Partials/TaskLists';
import JobHeader from 'Pages/Jobs/Partials/Header/JobHeader';
import JobOptionsMenu from 'Pages/Jobs/Partials/JobOptionsMenu';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import route from 'ziggy-js';

const ProcessSection = ({processes, quantity}) => {
  const totalProgress = useMemo(() => {
    return processes?.reduce((acc, process) => acc + process?.completed_quantity, 0) / (quantity * processes?.length);
  }, [processes, quantity]);

  return (
    <div className="relative flex items-center gap-3 flex-wrap border p-3 rounded">
      <div className="absolute inset-0 m-0.5 rounded-sm overflow-hidden">
        <div className={cls('h-full', totalProgress < 1 ? 'bg-orange-100 shimmer' : 'bg-green-100')} style={{
          width: `${totalProgress * 100}%`,
        }}/>
      </div>
      <div className="absolute -top-2 left-3 px-2 font-medium text-xs text-gray-500 bg-white border rounded-full">Processes</div>
      {processes?.map(process => (
        <div key={process?.id} className="flex-1 min-w-max space-y-1 z-10 mix-blend-multiply">
          <div className="font-medium text-sm text-gray-500">{process?.process?.name}</div>
          <ProgressBar value={process.completed_quantity} max={quantity}/>
        </div>
      ))}
    </div>
  );
};

const ViewJobModal = ({jobId, open, onClose}) => {
  const [{job, taskListStatuses}, setData] = useState({});
  const [files, setFiles] = useState(null);

  const loadJob = useCallback((id) => {
    axios.get(route('jobs.show', id)).then(({data}) => {
      setData(data);
    });
  }, []);

  const loadFiles = useCallback((id) => {
    if (files) return;
    axios.get(route('jobs.files.data', id)).then(({data: {data}}) => {
      setFiles(data);
    });
  }, [files]);

  useEffect(() => {
    if (jobId) {
      setData({});
      setShowComments(false);
      loadJob(jobId);
    }
    setFiles(null);
  }, [jobId]);

  const statusSegments = useMemo(() => {
    return Object.values(job?.job_products?.reduce((acc, product) => {
      acc[product.status?.value] = {
        name: product.status?.label,
        value: (acc[product.status?.value]?.value || 0) + product?.quantity,
        className: product?.status?.meta?.className,
      };

      return acc;
    }, {}) ?? {});
  }, [job]);

  const [showComments, setShowComments] = useState(false);

  return (
    <Modal open={open} onClose={onClose} size="7xl">
      <Modal.Content className="relative">
        <Modal.Header>
          <div className="flex items-center justify-between">
            <Transition
              show={!!job}
              enter="transition-opacity duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              {!!job &&
                <JobHeader job={job}/>
              }
            </Transition>
            <div className="flex items-center gap-2">
              {(job && !job?.deleted_at) && <JobOptionsMenu job={job}/>}
              <Modal.CloseButton onClose={onClose}/>
            </div>
          </div>
        </Modal.Header>
        {
          !!job ?
            <Tab.Group>
              <Tab.List
                className="flex items-center gap-5 ml-4 -mt-6 mb-6 font-medium text-gray-700 text-sm children:rounded ui-selected:children:bg-gray-200 children:px-3 children:py-2 children:duration-150">
                <Tab>Details</Tab>
                <Tab>Files</Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel>
                  <div className="flex flex-col gap-5">
                    <div className="contents lg:flex gap-5">
                      <div className="grow flex flex-col gap-5 min-h-0">
                        <div className="flex flex-col gap-2">
                          <div className="font-medium text-gray-500 mb-2">Item Statuses</div>
                          <PortionBar
                            segments={statusSegments}
                            classNames={{
                              segment: 'py-2 duration-500 overflow-hidden border-r-2 border-white last:border-0',
                            }}
                            legendType={LegendTypes.BOTTOM}
                            showQuantities
                          />
                          <div className="font-medium text-gray-500 mb-2">Ordered Items</div>
                          {job?.job_products?.map(jobProduct => (
                            <div key={jobProduct?.id} className="flex items-stretch border bg-white shadow-lg rounded overflow-hidden">
                              <div
                                className={cls('px-0.5 py-3 text-white text-sm text-center select-none font-medium', jobProduct.status?.meta?.className ?? 'bg-white')}
                                style={{
                                  writingMode: 'vertical-lr',
                                }}>
                                {jobProduct.status?.label}
                              </div>
                              <div className="grow self-center flex flex-col gap-3 p-3">
                                <Product
                                  {...jobProduct.product}
                                  {...jobProduct}
                                  price={jobProduct.price || jobProduct?.product?.markup_price}
                                  tags={jobProduct.product.tags}
                                  jobProductTags={jobProduct.tags}
                                />
                                <div className="flex items-center gap-2">
                                  <span className="font-medium text-sm text-gray-500">Received:</span>
                                  <div className="grow">
                                    <ProgressBar value={jobProduct?.received_quantity} max={jobProduct.quantity}/>
                                  </div>
                                  <div className="whitespace-nowrap">
                                    <span className="font-medium pr-1">{jobProduct?.received_quantity}</span>
                                    <span className="text-gray-500 text-sm">/{jobProduct.quantity}</span>
                                  </div>
                                </div>
                                {!!jobProduct?.job_product_processes?.length &&
                                  <ProcessSection processes={jobProduct?.job_product_processes} quantity={jobProduct?.quantity}/>
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        className="flex flex-col p-5 max-w-none lg:order-first lg:max-w-xl lg:min-w-52 lg:border-r"
                      >
                        <div className="text-lg font-semibold">Task Lists</div>
                        <TaskLists {...job} statuses={taskListStatuses}/>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 pt-5 mb-7 border-t">
                      <button
                        type="button"
                        className="flex items-center gap-1 text-gray-500 px-3 py-2 border shadow-sm rounded lg:p-0 lg:border-0 lg:shadow-none"
                        onClick={() => setShowComments(!showComments)}
                      >
                        <div className="font-medium">Comments</div>
                        {!job?.comments.length && <div className="text-xs">(none)</div>}
                        <Icon path={mdiChevronDown} className={cls('w-6 duration-150', showComments && 'rotate-180')}/>
                      </button>
                      <Transition
                        show={showComments}
                        enter="duration-150 ease-out"
                        enterFrom="opacity-0 -translate-y-2"
                        enterTo="opacity-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0 -translate-y-2"
                        leave="duration-150 ease-in"
                      >
                        <CommentSection
                          comments={job?.comments}
                          addRoute={job && route('jobs.comment', job.id)}
                          onCommentSubmitted={() => loadJob(jobId)}
                          onCommentDeleted={() => loadJob(jobId)}
                        />
                      </Transition>
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <JobFilesList files={files} loadFiles={() => loadFiles(jobId)}/>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            :
            <div className="flex flex-col items-center font-medium text-lg text-gray-500">Loading Data...</div>
        }
      </Modal.Content>
    </Modal>
  );
};

export default ViewJobModal;