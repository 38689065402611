import FileSystemObjectPreview from 'Components/FileSystem/FileSystemObjectPreview';
import React from 'react';
import route from 'ziggy-js';

const FileAttachmentsViewer = ({files, onObjectClick}) => {

  return (
    <div className="flex flex-wrap gap-10">
      {files?.length ? files?.map(({file, attached}) => (
          <div className="flex flex-col gap-2 w-52 min-w-52">
            <button
              type="button"
              onClick={() => onObjectClick({
                file: {
                  ...file,
                  previewUrl: file?.extra_metadata?.downloadUrl || route('file-system.read-file', {path: file?.path}),
                  openUrl: route('file-system.read-file', {path: file?.path}),
                },
                attached,
              })}
              className="border h-52 p-2 min-h-52 rounded-xl bg-white shadow-lg duration-100 hover:shadow-xl hover:shadow-blue-900/25 hover:border-blue-500"
            >
              <FileSystemObjectPreview key={file?.path} object={{
                ...file,
                previewUrl: file?.extra_metadata?.downloadUrl || route('file-system.read-file', {path: file?.path}),
              }}/>
            </button>
            <div className="font-medium text-lg text-center text-gray-700">{file?.path?.split('/').slice(-1)[0]}</div>
          </div>
        ))
        :
        <div className="flex items-center justify-center w-full font-medium text-gray-700 text-lg">
          <div>No Files Attached</div>
        </div>
      }
    </div>
  );
};

export default FileAttachmentsViewer;