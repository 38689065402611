import { ConfirmationModal } from '@webfox/webfox-ui';
import { Inertia } from '@inertiajs/inertia';
import route from 'ziggy-js';

const DuplicateJobModal = ({ open, job, onClose }) => {

  return (
    <ConfirmationModal
      title="Create New Work Order?"
      open={open}
      onConfirm={() => {
        Inertia.post(route('jobs.duplicate', job?.id))
        onClose()
      }}
      onCancel={onClose}
      confirmText="Create"
    >
      <div className="flex flex-col gap-2">
        <span>The following information will be copied to the new work order:</span>
        <ul className="list-disc ml-5 list-outside">
          <li>Customer</li>
          <li>Notes</li>
          <li>Products *</li>
          <li>Tasks</li>
          <li>Attached Files</li>
          <li>Drawings</li>
        </ul>
        <span className="text-xs text-gray-500">* Product information will be copied along with any assigned processes, attached files, drawings, tags, and notes</span>
      </div>
    </ConfirmationModal>
  );
};

export default DuplicateJobModal;