import {Menu} from '@headlessui/react';
import MenuItem from 'Components/MenuItem';
import MenuItemsWrapper from 'Components/MenuItemsWrapper';
import * as React from 'react';
import {useState} from 'react';
import useUser from 'Support/Hooks/useUser';
import {usePage} from "@inertiajs/inertia-react";
import MyobSync from "./MyobSync";
import BranchSelector from "./BranchSelector";

const AccountMenu = ({accountMenuItems}) => {
    const {avatar, name, branch_id} = useUser();
  const [trigger, setTriggerElement] = useState(null);
    const application = usePage()?.props?.application;

  return (
      <>
          <div className="flex divide-x">
              {application?.branches?.length > 1 && !branch_id &&
                  <BranchSelector/>
              }
              {application?.integrations?.myob?.connected &&
                  <MyobSync/>
              }
          </div>
          <div className="relative inline-block text-left shrink-0">
              <Menu>
                  {({open}) => (
                      <>
                          <Menu.Button
                              ref={setTriggerElement}
                              className="max-w-lg text-sm rounded py-2 pl-4 focus-ring"
                          >
                              <div
                                  aria-haspopup="true"
                                  aria-label="User menu"
                                  className="flex items-center text-base sm:space-x-3 max-w-full"
                                  id="user-menu"
                              >
                                  <img className="h-8 w-8 rounded-full" src={avatar} alt={`${name} avatar`}/>
                              </div>
                          </Menu.Button>

                          <MenuItemsWrapper show={open} trigger={trigger} width="w-64" grouped>
                              {accountMenuItems?.map((item, index) => (
                                  <MenuItem icon={item.icon} href={item.href} key={index}>{item.title}</MenuItem>
                              ))}
                          </MenuItemsWrapper>
                      </>
                  )}
              </Menu>
          </div>
      </>
  );
};

export default AccountMenu;