const lessons = Object.entries(import.meta.glob('Lessons/**/*.jsx', {eager: true})).map(([path, module]) => {
  const Lesson = module?.default;
  return {
    Lesson,
    path,
    name: Lesson?.lessonName || path.split('/').pop().replace('.jsx', ''),
    key: Lesson?.lessonKey || path,
    modalSize: Lesson?.modalSize,
    group: Lesson?.group,
    roleRestrictions: Lesson?.roleRestrictions,
    keywords: Lesson?.keywords,
  };
});

const getLessons = (...keys) => {
  if (keys.length === 0) {
    return lessons;
  }
  return lessons.filter(({key}) => keys.includes(key));
};

export default getLessons;