import cls from 'classnames';
import Tag from 'Components/Tags/Tag';
import React from 'react';

const TagOption = ({innerProps, data, tagProps, isMultiValue}) => (
  <div className={cls(isMultiValue ? 'cursor-pointer mr-2' : 'p-2 hover:bg-gray-100')} {...innerProps}>
    <div className={cls(isMultiValue ? 'contents' : 'pointer-events-none')}>
      <Tag {...data} {...tagProps}/>
    </div>
  </div>
);

export default TagOption;