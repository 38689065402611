import {Popover} from '@headlessui/react';
import {mdiClose} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'classnames';
import {format} from 'date-fns';
import React from 'react';
import {DateRangePicker} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const DateRangeFilter = ({startDate, endDate, onChange}) => {
  const selectionRange = {
    startDate: new Date(startDate || new Date()),
    endDate: new Date(endDate || new Date()),
    key: 'selection',
  };

  return (
    <Popover className="relative">
      <div className="inline-flex gap-2 px-3 py-2 border rounded duration-100 bg-white border-gray-300 hover:border-gray-400 whitespace-nowrap">
        <Popover.Button>
          <span className={cls(startDate && 'font-medium')}>{startDate ? format(selectionRange.startDate, 'dd MMM yy') : 'Any Date'}</span>
          {startDate?.toString() !== endDate?.toString() &&
            <>
              <span className="text-gray-500"> - </span>
              <span className={cls(endDate && 'font-medium')}>{endDate ? format(selectionRange.endDate, 'dd MMM yy') : 'Any Date'}</span>
            </>
          }
        </Popover.Button>
        {(!!startDate || !!endDate) &&
          <button type="button" className="flex items-center p-1 duration-100 hover:text-red-800 hover:bg-red-100 rounded"
                  onClick={() => onChange({startDate: null, endDate: null})}>
            <Icon path={mdiClose} className="w-4"/>
          </button>
        }
      </div>
      <Popover.Panel className="absolute inset-x-0 z-20">
        <div className="fixed -translate-x-1/2 bg-white border mt-5 p-2 rounded duration-150 shadow-lg hover:shadow-xl">
          <DateRangePicker ranges={[selectionRange]}
                           onChange={ranges => onChange({startDate: ranges.selection.startDate, endDate: ranges.selection.endDate})}/>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default DateRangeFilter;