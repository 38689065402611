import React from 'react';

const UserOption = ({innerProps, data}) => (
  <div className="p-2 hover:bg-gray-100 cursor-pointer" {...innerProps}>
    <div className="flex items-center gap-2">
      <img src={data.avatar} alt={`${data.name}'s Avatar`} className="w-10 rounded-full"/>
      <div>{data.name}</div>
    </div>
  </div>
);

export default UserOption;