import {mdiFormTextbox} from '@mdi/js';
import {Icon} from '@mdi/react';
import Lesson from 'Components/Lessons/Lesson';
import React from 'react';

const CommonValues = () => (
  <Lesson
    title="Common Values"
    subtitle="Values that are commonly entered in various places"
    sideClassName="bg-gradient-to-t from-orange-500 to-transparent"
    side={
      <div className="relative w-20 h-20 p-2 rounded-full bg-orange-200">
        <Icon path={mdiFormTextbox} className="text-orange-800"/>
      </div>
    }
  >
    <div className="space-y-2">
      <section>
        <h3 className="font-medium">What are Common Values?</h3>
        <p className="text-sm text-gray-700">
          Common Values are values that are commonly entered in various places. For example when entering
          the size of a product, there are a few common sizes that are used. Instead of having to type
          the same size over and over again, you can create a Common Value for that size and then select
          it from a list when entering the size
        </p>
      </section>
      <section>
        <h3 className="font-medium">How do I use them?</h3>
        <p className="text-sm text-gray-700">
          To use a Common Value, click the 'Add Common Value' button in the top right of the 'Common Values' page. Then
          enter the value and the relevant place it should show up. You can then select the Common Value from
          the list when entering the value
        </p>
      </section>
      <section>
        <h3 className="font-medium">What places can I use them in?</h3>
        <p className="text-sm text-gray-700">
          Common Values can be used in the following places:
        </p>
        <ul className="list-disc list-inside text-sm">
          <li>Product Size</li>
          <li>Product Colour</li>
          <li>Job Pre-Production Tasks</li>
          <li>Job Post-Production Tasks</li>
        </ul>
      </section>
    </div>
  </Lesson>
);

Object.assign(CommonValues, {
  lessonName: 'Common Values',
  lessonKey: 'commonValues',
  modalSize: '2xl',
  group: 'Settings',
  roleRestrictions: ['admin'],
  keywords: ['common', 'values', 'autocomplete', 'auto', 'complete', 'auto-complete', 'fill', 'autofill', 'auto-fill'],
});

export default CommonValues;