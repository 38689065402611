import {axios, Form, Formik, HorizontalFieldWrapper, Modal, PrimaryButton, SecondaryButton, TextareaField, useInertiaForm} from '@webfox/webfox-ui';
import cls from 'classnames';
import JobPing from 'Components/Pings/JobPing';
import ThreadIcon from 'Forms/Messages/Partials/ThreadIcon';
import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import * as Yup from 'yup';
import route from 'ziggy-js';

const validationSchema = Yup.object().shape({
  body: Yup.string().required(),
});

const ThreadOption = ({data, isFocused, innerProps}) => (
  <div {...innerProps} className={cls('flex items-center p-2 gap-2 cursor-pointer', isFocused && 'bg-gray-100')}>
    <ThreadIcon thread={data}/>
    <span>{data.name}</span>
  </div>
);

const ThreadSelect = ({value, onChange}) => {
  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios.get(route('message-threads.index')).then(({data}) => {
      setThreads(data);
    }).finally(() => setLoading(false));
  }, []);

  return (
    <Select
      placeholder="Select a message thread"
      isLoading={loading}
      isClearable
      components={{
        Option: ThreadOption,
        SingleValue: ThreadOption,
      }}
      options={threads}
      onChange={onChange}
      value={value}
      className="z-50"
    />
  );
};

const SendMessageModal = ({open, onClose, initialMessage = '', jobIds = []}) => {
  const [thread, setThread] = useState(null);

  const formikProps = useInertiaForm({
    defaultValues: {
      body: initialMessage,
      job_pings: jobIds,
    },
    submit: ({post, reset}) => post(route('messages.send', thread?.id), {
      onSuccess: () => {
        onClose();
        reset();
      },
    }),
    validationSchema,
  });

  return (
    <Formik {...formikProps}>
      {({isValid}) => (
        <Modal open={open} onClose={onClose}>
          <Modal.Content as={Form}>
            <Modal.Header title="Send Message"/>
            <div>
              <ThreadSelect value={thread} onChange={setThread}/>
            </div>
            <div className="space-y-6">
              <HorizontalFieldWrapper label="Message" name="body">
                <TextareaField/>
              </HorizontalFieldWrapper>
              {jobIds?.length &&
                <div className="flex flex-col gap-1">
                  <div className="pb-1 mb-1 text-gray-700 border-b">Pings</div>
                  {jobIds.map(jobId => (
                    <JobPing key={jobId} id={jobId}/>
                  ))}
                </div>
              }
            </div>
            <Modal.Actions>
              <SecondaryButton type="button" onClick={onClose}>Cancel</SecondaryButton>
              <PrimaryButton type="submit" disabled={!isValid || !thread}>Send</PrimaryButton>
            </Modal.Actions>
          </Modal.Content>
        </Modal>
      )}
    </Formik>
  );

};

export default SendMessageModal;