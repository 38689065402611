import {mdiFile, mdiFileDocument, mdiFilePdfBox, mdiImage} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'classnames';
import React, {useEffect, useState} from 'react';

const ImagePreview = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [props?.src]);

  return (
    <div className="relative w-full h-full">
      <Icon path={mdiImage} className={cls('absolute inset-0 p-5 text-blue-500 drop-shadow-lg', imageLoaded && 'opacity-0')}/>
      <img {...props} onLoad={() => setImageLoaded(true)} onError={() => setImageLoaded(false)}
           className={cls('w-full h-full object-contain duration-300', !imageLoaded && 'opacity-0')}/>
    </div>
  );
};

const FileSystemObjectPreview = ({object}) => {
  const elementMap = {
    '/image/.*/': <ImagePreview key={object?.previewUrl} src={object?.previewUrl} alt={object?.path}/>,
    '/application/pdf/': (
      <div className="relative m-5">
        <Icon path={mdiFileDocument} className="text-blue-500"/>
        <Icon path={mdiFilePdfBox} className="absolute bottom-0 right-0 w-10 p-0.5 bg-white rounded text-red-500"/>
      </div>
    ),
    '*': <Icon path={mdiFile} className="p-5 text-blue-500 drop-shadow-lg"/>,
  };

  return elementMap[Object?.keys(elementMap)?.find((type) => {
    return new RegExp(type?.slice(1, type?.length - 2)).test(object?.mime_type);
  })];
};

export default FileSystemObjectPreview;