import {Modal} from '@webfox/webfox-ui';
import JobUpdateList from 'Forms/Jobs/Partials/JobUpdateList';
import React from 'react';

const ViewJobUpdatesModal = ({open, onClose, jobId}) => {

  return (
    <Modal open={open} onClose={onClose} size="5xl">
      <Modal.Content className="relative">
        <Modal.Header title={<>Work Order #{jobId} Log</>}/>
        <JobUpdateList jobId={jobId}/>
      </Modal.Content>
    </Modal>
  );
};

export default ViewJobUpdatesModal;