import {mdiPlusCircle} from '@mdi/js';
import {Icon} from '@mdi/react';
import {ConfirmationModal, FieldContext} from '@webfox/webfox-ui';
import SubFieldWrapper from 'Components/Forms/SubFieldWrapper';
import {FieldArray, FormikContext, useField} from 'formik';
import React, {forwardRef, useContext, useState} from 'react';

const ListAddButton = ({push, entityName}) => (
  <button
    type="button"
    onClick={() => push()}
    className="flex items-center justify-center gap-2 px-3 py-2 font-medium bg-indigo-100 hover:bg-opacity-75 rounded"
  >
    <span>Add {entityName}</span>
    <Icon path={mdiPlusCircle} className="w-6"/>
  </button>
)

const ListField = forwardRef(({entityName, children, confirmRemovals, addDisabled, components = {}, initialValues = '', ...props}, ref) => {
  const {name} = useContext(FieldContext);
  const [field] = useField(name);
  const {setFieldValue} = useContext(FormikContext);
  const [removingItem, setRemovingItem] = useState(null);

  const {AddButton} = {
    AddButton: ListAddButton,
    ...components,
  };

  return (
    <FieldArray name={name}>
      {({remove, push}) => (
        <>
          <div ref={ref} className="flex flex-col" {...props}>
            {!!field.value && !!field.value?.length && field.value.map((fields, index) => (
              <FieldContext.Provider value={{name: `${name}.${index}`}} key={index}>
                {children({
                  SubFieldWrapper,
                  removeItem: () => confirmRemovals ? setRemovingItem(index) : remove(index),
                  value: field.value,
                  setValue: (subFieldName, value) => setFieldValue(`${name}.${index}${subFieldName ? '.' + subFieldName : ''}`, value),
                  itemIndex: index,
                })}
              </FieldContext.Provider>
            ))}
            {!addDisabled &&
              <AddButton push={() => push(initialValues)} entityName={entityName}/>
            }
          </div>
          {confirmRemovals &&
            <ConfirmationModal
              onCancel={() => setRemovingItem(null)}
              onConfirm={() => {
                remove(removingItem);
                setRemovingItem(null);
              }}
              open={removingItem != null}
              confirmText="Remove"
              title="Are you sure?"
            >
              Are you sure you want to remove this {entityName}?
            </ConfirmationModal>
          }
        </>
      )}
    </FieldArray>
  );
});

export default ListField;