import {mdiLoading} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'classnames';
import FileAttachmentsViewer from 'Components/FileSystem/FileAttachmentsViewer';
import FileSystemObjectPanelContent from 'Components/FileSystem/FileSystemObjectPanelContent';
import JobFileAttachedModel from 'Forms/Jobs/Partials/JobFileAttachedModel';
import React, {useEffect, useState} from 'react';

const JobFilesList = ({files, loadFiles}) => {
  const [selectedObject, setSelectedObject] = useState();

  useEffect(() => {
    loadFiles();
  }, []);

  return (
    <div className="relative p-10 m-2 border rounded-lg bg-gray-50 overflow-hidden">
      {files ?
        <>
          <FileAttachmentsViewer files={files} onObjectClick={setSelectedObject}/>
          <div
            className={cls('absolute inset-y-0 flex flex-col right-0 bg-white duration-500 overflow-y-auto max-w-lg', selectedObject ? 'shadow-xl' : 'translate-x-full')}>
            <div className="min-h-max">
              <FileSystemObjectPanelContent object={selectedObject?.file} onClose={() => setSelectedObject(null)}/>
            </div>
            <div className="flex flex-col gap-2 p-5">
              <div className="flex items-center gap-2 pb-1">
                <span className="font-medium text-sm text-gray-700">Attached</span>
                <span className="text-xs text-gray-500">(from this work order)</span>
              </div>
              {selectedObject?.attached?.map?.((model) => (
                <JobFileAttachedModel model={model}/>
              ))}
            </div>
          </div>
        </>
        :
        <div className="flex items-center justify-center gap-5 font-medium text-gray-700">
          <Icon path={mdiLoading} className="animate-spin w-10"/>
          <div>Loading Files...</div>
        </div>
      }
    </div>
  );
};

export default JobFilesList;