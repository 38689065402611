import cls from 'classnames';
import React from 'react';

const Lesson = ({title, subtitle, sideClassName, side, children}) => {
  return (
    <div className="flex items-stretch">
      <div className={cls('p-5 shrink-0 relative overflow-hidden', sideClassName || 'bg-gradient-to-t from-blue-500 to-transparent')}>
        {side}
        <div className="absolute inset-0 h-full mix-blend-color-burn noise-filter" style={{filter: 'grayscale(1) contrast(150%) brightness(50%)'}}/>
      </div>
      <div className="flex flex-col p-5 overflow-y-auto">
        {!!(title || subtitle) &&
          <div className="mb-5">
            {!!title && <div className="text-2xl font-semibold">{title}</div>}
            {!!subtitle && <div className="text-xs text-gray-500">{subtitle}</div>}
          </div>
        }
        {children}
      </div>
    </div>
  );
};

export default Lesson;