import {Form, Formik, Modal, PrimaryButton, SecondaryButton, useInertiaForm} from '@webfox/webfox-ui';
import {FormFields, validationSchema} from 'Forms/Invoices/Form';
import React from 'react';
import route from 'ziggy-js';

const AddInvoiceModal = ({open, onClose, jobId}) => {
  const formikProps = useInertiaForm({
    defaultValues: {
      reference_num: '',
      details: '',
    },
    submit: ({post, reset}) => post(route('invoices.store', jobId), {
      onSuccess: () => {
        onClose();
        reset();
      },
    }),
    validationSchema,
  });

  return (
    <Formik {...formikProps}>
      {({isValid, errors}) => (
        <Modal open={open} onClose={onClose}>
          <Modal.Content as={Form}>
            <Modal.Header title="Add Invoice"/>
            <div className="space-y-6">
              <FormFields/>
            </div>
            <Modal.Actions>
              <SecondaryButton type="button" onClick={onClose}>Cancel</SecondaryButton>
              <PrimaryButton type="submit" disabled={!isValid}>Add</PrimaryButton>
            </Modal.Actions>
          </Modal.Content>
        </Modal>
      )}
    </Formik>
  );
};

export default AddInvoiceModal;