import cls from 'classnames';
import React from 'react';
import {formatCurrency} from 'Support/valueFormatters';

const Product = ({name, code, full_code, size, colour, supplier, drawings, tags, jobProductTags, notes, price, quantity, is_supplied}) => {

  return (
    <div className="flex flex-col gap-1">
      <div className="contents sm:flex items-center justify-between gap-5">
        <div className="flex items-center gap-2">
          <div className={cls('text-lg font-medium pr-2 border-r', is_supplied && 'text-red-500')}>{name}</div>
          <div className="text-sm">{full_code || code}</div>
        </div>
        {quantity &&
          <div className="flex items-center gap-2">
            <div className="font-medium text-gray-500 text-sm">Qty.</div>
            <div className="px-2 py-1 font-semibold text-lg bg-gray-100 rounded">{quantity}</div>
          </div>
        }
      </div>
      <div className="flex items-center gap-2 justify-between">
        <div className="flex items-center gap-2">
          {!!size &&
            <div className="contents">
              <div className="font-medium text-gray-500 text-sm">Size</div>
              <div className="px-2 py-1 bg-gray-100 rounded">{size}</div>
            </div>
          }
          {!!colour &&
            <div className="contents">
              <div className="font-medium text-gray-500 text-sm">Colour</div>
              <div className="px-2 py-1 bg-gray-100 rounded">{colour}</div>
            </div>
          }
        </div>
        {!!price &&
          <div className="flex items-center gap-2">
            <div className="font-medium text-gray-500 text-sm">Price</div>
            <div className="px-2 py-1 bg-gray-100 rounded">{formatCurrency(price)}</div>
          </div>
        }
      </div>
    </div>
  );
};

export default Product;