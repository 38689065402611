import {mdiArrowULeftTop, mdiDelete} from '@mdi/js';
import {Icon} from '@mdi/react';
import React, {useRef, useState} from 'react';
import CanvasDraw from 'react-canvas-draw';
import {HexAlphaColorPicker} from 'react-colorful';

const Paint = ({data, canvasRef, onChange, canvasHeight = 800, canvasWidth = 800, ...props}) => {
  const canvas = useRef();
  const [brushColour, setBrushColour] = useState('#000');
  const [brushRadius, setBrushRadius] = useState(2);

  return (
    <div className="flex items-center gap-10">
      <div className="flex flex-col gap-5">
        <div className="text-lg font-bold border-b">Brush Settings</div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <span className="font-bold">Brush Colour</span>
            <div className="flex items-center gap-2">
              <div className="text-sm font-medium text-gray-400">{brushColour}</div>
              <div className="h-5 w-5 border border-white shadow rounded" style={{backgroundColor: brushColour}}/>
            </div>
          </div>
          <HexAlphaColorPicker color={brushColour} onChange={setBrushColour}/>
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <span className="font-bold">Brush Size</span>
            <div>{brushRadius}px Radius</div>
          </div>
          <div className="flex items-center justify-between">
            <input value={brushRadius} type="range" min="1" max="24" step="0.1" onChange={({target: {value}}) => setBrushRadius(value)}/>
            <div className="flex items-center justify-center w-[48px] h-[48px]">
              <div className="rounded-full" style={{
                width: `${brushRadius * 2}px`,
                aspectRatio: '1/1',
                backgroundColor: brushColour,
              }}/>
            </div>
          </div>
          <div className="text-lg font-bold border-b">Actions</div>
          <div className="flex flex-col items-stretch gap-2">
            <button type="button" onClick={() => canvas?.current?.undo()}
                    className="flex items-center justify-center gap-2 py-2 px-4 text-gray-500 hover:shadow active:bg-gray-50 duration-100 rounded border">
              <span>Undo</span>
              <Icon path={mdiArrowULeftTop} className="w-6"/>
            </button>
            <button type="button" onClick={() => canvas?.current?.eraseAll()}
                    className="flex items-center justify-center gap-2 py-2 px-4 text-gray-500 hover:shadow active:bg-gray-50 duration-100 rounded border">
              <span>Clear</span>
              <Icon path={mdiDelete} className="w-6"/>
            </button>
          </div>
        </div>
      </div>
      <div className="shadow-xl">
        <CanvasDraw
          onChange={onChange}
          ref={(ref) => {
            canvas.current = ref;
            if (canvasRef) {
              canvasRef.current = ref;
            }
          }}
          saveData={data}
          brushColor={brushColour}
          brushRadius={brushRadius}
          lazyRadius={0}
          canvasHeight={canvasHeight}
          canvasWidth={canvasWidth}
          {...props}
        />
      </div>
    </div>
  );
};

export default Paint;