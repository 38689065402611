import {Link} from '@inertiajs/inertia-react';
import {mdiBell} from '@mdi/js';
import {Icon} from '@mdi/react';
import React from 'react';

const BasePing = ({route, action, children}) => {
  const Component = route ? Link : 'button';

  if (route && action) throw new Error('BasePing can only have one of route or action');
  if (!(route || action)) throw new Error('BasePing must have one of route or action');

  return (
    <Component
      {...(route && {href: route})}
      {...(action && {onClick: action, type: 'button'})}
      className="flex items-center px-2 py-1 border border-primary text-gray-900 bg-white hover:bg-primary/10 duration-100 rounded shadow-sm"
    >
      <div className="p-2 bg-primary text-white rounded-full">
        <Icon path={mdiBell} className="w-7"/>
      </div>
      {children}
    </Component>
  );
};

export default BasePing;