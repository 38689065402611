import {initInertiaApp} from 'Support/init';
import 'Style/app.css';
import {MainLayout} from '@webfox/webfox-ui'
import Logo from 'Images/logo_dark.png';
import MessageMenu from "./Components/MessageMenu";
import AllLessonsButton from "./Components/Lessons/AllLessonsButton";
import DevModeListener from "./Components/DevMode/DevModeListener";
import DevModeOverlay from "./Components/DevMode/DevModeOverlay";

initInertiaApp({
    resolve: async name => {
        const comps = import.meta.glob('./Pages/**/*.jsx');
        const match = comps[`./Pages/${name}.jsx`];
        return (await match()).default;
    },
    layout: (props) => (
        <DevModeListener>
            <MainLayout
                {...props}
                leftNavMainClassName="bg-zinc-900"
                largeLogoSrc={Logo}
                smallLogoSrc={Logo}
                activeClassName="bg-zinc-400"
                largeLogoClassName="w-full h-full object-contain p-2"
            >
                {props.children}
                <AllLessonsButton/>
                <MessageMenu/>
                <DevModeOverlay/>
            </MainLayout>
        </DevModeListener>
    )
});
