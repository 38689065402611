import {Menu, Transition} from '@headlessui/react';
import {mdiCheckboxBlankOff, mdiCheckboxBlankOutline, mdiCheckboxIntermediateVariant, mdiCheckboxMarked} from '@mdi/js';
import {Icon} from '@mdi/react';
import cls from 'classnames';
import React, {useMemo} from 'react';

const statusIcons = {
  pending: <Icon path={mdiCheckboxBlankOutline} className="text-gray-900"/>,
  in_progress: <Icon path={mdiCheckboxIntermediateVariant} className="text-amber-500"/>,
  completed: <Icon path={mdiCheckboxMarked} className="text-green-500"/>,
  cancelled: <Icon path={mdiCheckboxBlankOff} className="text-red-500"/>,
};

const StatusSelector = ({onStatusChange = () => {}, currentStatus, statuses}) => {
  const currentStatusIndex = useMemo(() => {
    if (currentStatus) {
      const index = statuses.findIndex(({value}) => value === currentStatus?.value);
      return index < 0 ? 0 : index;
    } else {
      return 0;
    }
  }, [currentStatus, statuses]);

  return (
    <Menu as="div" className="flex relative">
      <Menu.Button className="w-6 h-6">
        {statusIcons[currentStatus?.value || 'pending']}
      </Menu.Button>
      <Transition
        enter="duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="z-10"
      >
        <Menu.Items className="absolute flex flex-col -top-2 -left-3 bg-white border rounded z-10 shadow duration-100 overflow-hidden" style={{
          transform: `translateY(-${currentStatusIndex * (1 / statuses?.length) * 100}%)`,
        }}>
          {statuses.map(({value, label}) => (
            <Menu.Item key={value}>
              {({active}) => (
                <button
                  type="button"
                  onClick={() => onStatusChange(value)}
                  className={cls('flex items-center gap-2 px-3 py-2', active && 'bg-gray-100', value === currentStatus?.value && 'text-primary')}
                >
                  <div className="w-6 h-6">
                    {statusIcons[value]}
                  </div>
                  <span className="whitespace-nowrap">{label}</span>
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default StatusSelector;