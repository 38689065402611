import React, {useEffect, useMemo, useRef, useState} from 'react';

const CollapseContainer = ({show, direction = 'lr', duration = 300, children, style, ...props}) => {
  const collapseRef = useRef();
  const [size, setSize] = useState({width: 0, height: 0});

  const observer = useRef(new ResizeObserver((entries) => {
    for (const entry of entries) {
      setSize({
        width: entry.target.offsetWidth,
        height: entry.target.offsetHeight,
      });
    }
  }));

  const insetDimensions = useMemo(() => {
    return {
      top: direction === 'bt' && !show ? '100%' : 0,
      bottom: direction === 'tb' && !show ? '100%' : 0,
      left: direction === 'rl' && !show ? '100%' : 0,
      right: direction === 'lr' && !show ? '100%' : 0,
    };
  }, [size, show, direction]);

  useEffect(() => {
    if (collapseRef.current) {
      observer.current.observe(collapseRef.current);
      return () => observer.current.disconnect();
    }
  }, [collapseRef]);

  return (
    <div ref={collapseRef} style={{
      transitionDuration: `${duration}ms`,
      clipPath: `inset(${insetDimensions.top} ${insetDimensions.right} ${insetDimensions.bottom} ${insetDimensions.left})`,
      [`margin${{
        lr: 'Right',
        rl: 'Left',
        bt: 'Top',
        tb: 'Bottom',
      }[direction]}`]: 0 - (show ? 0 : ['lr', 'rl'].includes(direction) ? size.width : size.height),
      ...style,
    }} {...props}>
      {children}
    </div>
  );
};

export default CollapseContainer;