import {mdiDelete, mdiPlus} from '@mdi/js';
import {Icon} from '@mdi/react';
import Tippy from '@tippyjs/react';
import {FieldContext, Modal, PrimaryButton} from '@webfox/webfox-ui';
import cls from 'classnames';
import PaintField from 'Components/Forms/PaintField';
import {FieldArray, useField} from 'formik';
import React, {useContext, useMemo, useState} from 'react';

const DrawingModalField = ({open, onClose, actions, loading, ...props}) => {
  const {name} = useContext(FieldContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [{value}] = useField(name);

  const drawingData = useMemo(() => (
    value[currentIndex]?.drawing
  ), [currentIndex]);

  return (
    <Modal open={open} onClose={onClose} size="4xl">
      <Modal.Content withoutPadding>
        <FieldArray name={name}>
          {({push, remove}) => (
            <div className="flex gap-3">
              <div className="flex flex-col divide-y bg-gray-100">
                {[...Array(value?.length).keys()].map((index) => (
                  <button
                    type="button"
                    className={cls('flex items-center justify-center w-10 h-10 duration-150 text-gray-500 hover:text-gray-700', index === currentIndex ? 'bg-white' : 'hover:bg-gray-200')}
                    onClick={() => setCurrentIndex(index)}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  type="button"
                  className="group w-10 h-10 flex items-center justify-center text-gray-700"
                  onClick={() => {
                    push({drawing: ''});
                    setCurrentIndex(value?.length);
                  }}
                >
                  <Icon path={mdiPlus} className="w-8 p-1 duration-150 group-hover:bg-green-200 group-hover:text-green-800 rounded-full"/>
                </button>
              </div>
              <div className="flex items-start p-5 pl-0">
                <div className="w-8">
                  {value?.length > 1 &&
                    <Tippy content="Delete this Drawing">
                      <button
                        type="button"
                        className="p-1 w-7 rounded-full duration-100 text-gray-700 hover:bg-red-200 hover:text-red-800"
                        onClick={() => {
                          remove(currentIndex);
                          setCurrentIndex(Math.max(0, currentIndex - 1));
                        }}
                      >
                        <Icon path={mdiDelete}/>
                      </button>
                    </Tippy>
                  }
                </div>
                <FieldContext.Provider value={{name: `${name}.${currentIndex}.drawing`}}>
                  <PaintField key={currentIndex} canvasHeight={500} canvasWidth={500} saveData={drawingData}/>
                </FieldContext.Provider>
              </div>
            </div>
          )}
        </FieldArray>
        <Modal.Actions withoutMargin>
          {actions || <PrimaryButton onClick={onClose}>Close</PrimaryButton>}
        </Modal.Actions>
        <div
          className={cls('absolute inset-0 duration-150', loading ? 'opacity-100 bg-gray-500/25 backdrop-blur backdrop-grayscale' : 'opacity-0 pointer-events-none')}/>
      </Modal.Content>
    </Modal>
  );
};

export default DrawingModalField;