import {Transition} from '@headlessui/react';
import {
  mdiArchive,
  mdiAutoFix,
  mdiBriefcaseAccount,
  mdiBrush,
  mdiCalendarAlert,
  mdiCalendarClock,
  mdiCash100, mdiCashMultiple,
  mdiClose,
  mdiPlus,
  mdiStoreMarker
} from '@mdi/js';
import {Icon} from '@mdi/react';
import Tippy from '@tippyjs/react';
import {formatLongDate} from '@webfox/webfox-ui';
import axios from 'axios';
import cls from 'classnames';
import TagOption from 'Components/Forms/SelectOptions/TagOption';
import {LessonButton} from 'Components/Lessons/LessonModal';
import StatusViewer from 'Components/Status/StatusViewer';
import Tag from 'Components/Tags/Tag';
import DrawingModal from 'Forms/Drawings/DrawingModal';
import LocationPopover from 'Pages/Jobs/Partials/Header/LocationPopover';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Select from 'react-select';
import useUser from 'Support/Hooks/useUser';
import tailwindTheme from 'tailwind-theme';
import route from 'ziggy-js';

const InfoLine = ({as = 'div', icon, children, tooltip}) => {
  const Element = as;

  return (
    <Tippy content={tooltip} disabled={!tooltip}>
      <Element className="flex items-center gap-2 text-sm font-normal text-gray-800 whitespace-nowrap">
        <Icon path={icon} size={0.8}/>
        <span className="flex items-center gap-2">{children}</span>
      </Element>
    </Tippy>
  );
};

const Drawings = ({open, onClose, job}) => {
  const [drawings, setDrawings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLoading(true);
      axios.get(route('jobs.drawings', job)).then(({data}) => {
        setDrawings(data);
      }).finally(() => setLoading(false));
    }
  }, [open, job]);

  const Modal = useCallback((props) => (
    <DrawingModal drawings={drawings} {...props}/>
  ), [drawings]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      drawings={drawings}
      route={route('jobs.update-drawings', job)}
      loading={loading}
    />
  );
};

const AutoTagSection = ({autoTags = {}}) => {
  const [showAutoTags, setShowAutoTags] = useState(false);

  return (
    <div className="flex items-center gap-2">
      <Tippy content={`${showAutoTags ? 'Hide' : 'Show'} Magic Tags`}>
        <button type="button" onClick={() => setShowAutoTags(!showAutoTags)}
                className={cls('p-2 rounded-full duration-100 hover:bg-blue-200 hover:text-blue-800', showAutoTags ? 'bg-blue-200 text-blue-800' : 'text-gray-800')}>
          <Icon path={mdiAutoFix} className="w-3"/>
        </button>
      </Tippy>
      <Transition
        show={showAutoTags}
        className="relative flex items-center flex-wrap gap-1 border border-blue-200 p-1 -my-1 rounded"
        enter="transition origin-left ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition origin-left ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {Object.entries(autoTags).map(([type, {tags, meta}]) => (
          !!tags?.length &&
          <Tippy content={meta?.description} disabled={!meta?.description}>
            <div className="flex items-center gap-1 pr-1 border-r border-r-blue-200 last-of-type:border-0 last-of-type:pr-0">
              {tags?.map((tag) => (
                <Tag key={`${type}.${tag}`} name={tag}
                     style={{background: tailwindTheme.colors.blue[200], color: tailwindTheme.colors.blue[800]}}/>
              ))}
            </div>
          </Tippy>
        ))}
        <span className="contents">
        <Tippy content="What is this?">
          <div className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 scale-50">
            <LessonButton lessonKey="magicTags"/>
          </div>
        </Tippy>
        </span>
      </Transition>
    </div>
  );
};

const JobHeader = ({job}) => {
  const [status, setStatus] = useState(job?.status);
  const [tags, setTags] = useState(job?.tags || []);
  const [tagOptions, setTagOptions] = useState(job?.tag_options || []);
  const [addingTag, setAddingTag] = useState(false);
  const [drawing, setDrawing] = useState(false);
  const [location, setLocation] = useState(job?.location);
  const user = useUser();

  const canEdit = useMemo(() => (
    ['admin'].includes(user.role.value)
  ), [user]);

  const onStatusChange = useCallback((status) => {
    axios.put(route('jobs.update-status', {job: job.id}), {status: status?.value}).then(({data}) => {
      setStatus(data);
    });
  }, []);

  const removeTag = useCallback((tag) => {
    axios.put(route('jobs.remove-tag', {job: job.id}), {tag_id: tag?.id}).then(() => {
      setTags(tags => tags.filter((t) => t.id !== tag.id));
    });
  }, []);

  const addTag = useCallback((tag) => {
    setAddingTag(false);
    axios.put(route('jobs.add-tag', {job: job.id}), {tag_id: tag?.id}).then(({data}) => {
      setTags(tags => [...tags, data]);
    });
  }, []);

  const onLocationChange = useCallback((location) => {
    job.location = location;
    setLocation(location);
  }, [job]);

  useEffect(() => {
    job.status = status;
  }, [status]);

  useEffect(() => {
    setTagOptions(job?.tag_options?.filter((tag) => !tags.find((t) => t.id === tag.id)));
    job.tags = tags;
  }, [tags, job?.tag_options]);

  const overdue = useMemo(() => {
    return new Date() > new Date(job?.due_at);
  }, [job?.due_at]);

  return (
    <>
      <div className="flex items-center gap-5 p-5">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-5 text-xl">
            <div className="font-semibold whitespace-nowrap">WO #{job?.id.toString().padStart(8, '0')}</div>
            <StatusViewer {...['admin'].includes(user?.role?.value) && {onChange: onStatusChange}} currentStatus={status}
                          statuses={job?.status_options}
                          fade/>
            <button type="button" onClick={() => setDrawing(true)}
                    className="p-1 duration-100 bg-gray-200 hover:bg-blue-200 hover:text-blue-800 rounded-full">
              <Icon path={mdiBrush} className="w-5"/>
            </button>
          </div>
          <div className="flex items-center gap-5">
            <InfoLine icon={mdiBriefcaseAccount} tooltip="Customer">
              {!!job?.customer?.flags?.includes?.('cash') &&
                <div
                  className="flex items-center gap-2 pl-1 pr-1 py-0.5 bg-green-200 rounded text-xs font-medium text-green-700">
                  <Icon path={mdiCashMultiple} size={0.8} />
                  <span>Cash</span>
                </div>
              }
              {job?.customer?.name}
            </InfoLine>
              <InfoLine icon={mdiStoreMarker} tooltip="Branch">{job?.branch?.name}</InfoLine>
            <InfoLine icon={mdiArchive} tooltip="Storage Location">
                {canEdit ? <LocationPopover job={job} location={location} onLocationChange={onLocationChange}/> : location?.name || 'N/A'}
            </InfoLine>
            {!!job?.due_at &&
              <InfoLine icon={overdue ? mdiCalendarAlert : mdiCalendarClock} tooltip="Due on">
                <span className={cls(overdue && 'text-orange-500 font-medium')}>{formatLongDate(job?.due_at)}</span>
              </InfoLine>
            }
            <div className="flex items-center flex-wrap gap-1 pl-2 border-l">
              <AutoTagSection autoTags={job?.auto_tags}/>
              {job?.tags?.map(tag =>
                <Tag
                  key={tag.id}
                  {...tag}
                  {...canEdit &&
                  {endButton: <Tag.Button icon={mdiClose} onClick={() => removeTag(tag)}/>}
                  }
                />)}
              {!(addingTag || !tagOptions?.length) &&
                <button
                  type="button"
                  onClick={() => setAddingTag(true)}
                  className="p-1 rounded-full duration-100 bg-gray-200 text-gray-800 hover:bg-green-200 hover:text-green-800"
                >
                  <Icon path={mdiPlus} className="w-5"/>
                </button>
              }
              <Transition
                show={addingTag}
                className="relative z-50"
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="text-base font-normal absolute top-0 -translate-y-1/2">
                  <Select
                    components={{
                      Option: TagOption,
                    }}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    options={tagOptions}
                    onChange={addTag}
                    defaultMenuIsOpen
                    autoFocus
                    onBlur={() => setAddingTag(false)}
                    className="w-max"
                  />
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
      <Drawings open={drawing} onClose={() => setDrawing(false)} job={job?.id}/>
    </>
  );
};

export default JobHeader;