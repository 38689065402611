import cls from 'classnames';
import React, {useMemo} from 'react';

const ProgressBar = ({
  min = 0,
  max = 100,
  value = 0,
  showProgress,
  classNames: {
    track = 'h-2 bg-gray-200 rounded-full overflow-hidden',
    bar = 'h-full rounded-r-full duration-500 ease-in-out',
    barIncomplete = 'bg-primary',
    barComplete = 'bg-green-500',
    progressText = 'text-gray-900',
    progressTextComplete = '',
    progressTextIncomplete = '',
    maxText = 'text-xs text-gray-500',
    maxTextComplete = '',
    maxTextIncomplete = '',
  } = {},
}) => {

  const progress = useMemo(() => (
    Math.min(Math.max(value / (max - min), 0), 1)
  ), [max, min, value]);

  return (
    <div className="flex flex-col items-stretch">
      {showProgress &&
        <div>
          <span className={cls(progressText, progress < 1 ? progressTextIncomplete : progressTextComplete)}>{value}</span>
          <span className={cls(maxText, progress < 1 ? maxTextIncomplete : maxTextComplete)}>/{max}</span>
        </div>
      }
      <div className={track}>
        <div
          className={cls(bar, progress < 1 ? barIncomplete : barComplete)}
          style={{width: (progress * 100) + '%'}}
        />
      </div>
    </div>
  );
};

export default ProgressBar;