import {Tree, useTree} from '@webfox/webfox-ui';
import axios from 'axios';
import cls from 'classnames';
import generateCheckListTree from 'Components/CheckList/generateCheckListTree';
import StatusSelector from 'Components/CheckList/StatusSelector';
import React, {useCallback, useState} from 'react';
import route from 'ziggy-js';

const Item = ({node: {data: task}, statuses, ...props}) => {
  const [status, setStatus] = useState(task?.status);

  const onStatusChange = useCallback((pickedStatus) => {
    if (pickedStatus !== status?.value) {
      axios.put(route('check-list-items.status', task.id), {status: pickedStatus}).then(({data}) => {
        task.status = data;
        setStatus(data);
      });
    }
  }, [task, status]);

  return (
    <div className="flex items-center gap-2">
      <StatusSelector currentStatus={status} onStatusChange={onStatusChange} statuses={statuses}/>
      <div
        className={cls('min-w-0 break-words', status?.value === 'cancelled' && 'line-through text-gray-500')}>{task?.name || task?.type?.label}</div>
    </div>
  );
};

const Branch = ({children}) => (
  !!children?.length ?
    <div className="flex flex-col gap-1 my-1 ml-3 pl-3 border-l">
      {children}
    </div>
    : null
);


const List = ({list, statuses}) => {
  const tree = useTree(list?.all_items, [list], {
    startExpanded: true,
    generatorFunction: generateCheckListTree,
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="text-xl font-medium">{list.name || list?.type?.label}</div>
      {list?.all_items?.length ?
        <Tree
          tree={tree}
          dndOptions={{
            ownProvider: true,
          }}
          components={{
            Node: props => <Item key={props.node.id} {...props} statuses={statuses}/>,
            Branch,
          }}
        />
        :
        <div className="text-gray-500">No tasks</div>
      }
    </div>
  );
};

const TaskLists = ({check_lists = [], statuses}) => {

  return (
    <div className="flex flex-col gap-5">
      {check_lists?.map((checkList, index) => (
        <List key={index} list={checkList} statuses={statuses}/>
      ))}
    </div>
  );
};

export default TaskLists;