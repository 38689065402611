import {
  mdiAlertBox,
  mdiAlertCircle,
  mdiBasket,
  mdiBroadcast,
  mdiCalendarText,
  mdiClipboardList,
  mdiContentDuplicate,
  mdiFile,
  mdiPencil,
  mdiReceiptTextOutline,
  mdiReceiptTextPlus
} from '@mdi/js';
import OptionsMenu, { isActiveRoute, OptionMenuItem } from 'Components/OptionsMenu';
import AddInvoiceModal from 'Forms/Invoices/AddInvoiceModal';
import ViewJobUpdatesModal from 'Forms/Jobs/ViewJobUpdatesModal';
import SendMessageModal from 'Forms/Messages/SendMessageModal';
import AddMistakeModal from 'Forms/Mistakes/AddMistakeModal';
import React, { useMemo, useState } from 'react';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';
import DuplicateJobModal from 'Forms/Jobs/DuplicateJobModal';


const JobOptionsMenu = ({job, ...props}) => {
  const [pinging, setPinging] = useState(false);
  const [duplicatingJob, setDuplicatingJob] = useState(false);
  const [addingMistake, setAddingMistake] = useState(false);
  const [viewingUpdates, setViewingUpdates] = useState(false);
  const [invoicing, setInvoicing] = useState(false);

  const user = useUser();
  const canEdit = useMemo(() => ['admin'].includes(user.role.value), [user]);

  return (
    <>
      <OptionsMenu {...props}>
        {canEdit &&
          <>
            <OptionMenuItem icon={mdiPencil} text="Edit" route={route('jobs.edit', job.id)}
                            isActive={isActiveRoute(['jobs.edit', {job: job.id}])}/>
            <OptionMenuItem icon={mdiContentDuplicate} text="Clone Work Order"
                            action={() => setDuplicatingJob(true)} />
            <OptionMenuItem icon={mdiFile} text="Files" route={route('jobs.files', job.id)}
                            isActive={isActiveRoute(['jobs.files', {job: job.id}])}/>
            <OptionMenuItem icon={mdiClipboardList} text="Task Lists" route={route('jobs.tasks', job.id)}
                            isActive={isActiveRoute(['jobs.tasks', {job: job.id}])}/>
            <OptionMenuItem icon={mdiBasket} text="Sample Orders" route={route('jobs.sample-orders', job.id)}
                            isActive={isActiveRoute(['jobs.sample-orders', {job: job.id}])}/>
          </>
        }

        <OptionMenuItem icon={mdiBroadcast} text="Ping" action={() => setPinging(true)}/>
        <OptionMenuItem icon={mdiCalendarText} text="Show Logs" action={() => setViewingUpdates(true)}/>
        {canEdit &&
          <>
            <OptionMenuItem icon={mdiAlertBox} text="Show Mistakes" route={route('jobs.mistakes', {job: job?.id})}/>
          </>
        }

        <OptionMenuItem icon={mdiAlertCircle} text="Register Mistake" action={() => setAddingMistake(true)} inactiveTextClasses="text-red-600"/>
        {canEdit &&
          <>
            <div className="px-3 py-1 mb-1 text-xs font-medium text-gray-500 border-b text-left">Invoices</div>
            <OptionMenuItem icon={mdiReceiptTextPlus} text="Add Invoice" action={() => setInvoicing(true)}/>
            <OptionMenuItem icon={mdiReceiptTextOutline} text="Show Invoices" route={route('invoices.index', {job: job?.id})}/>
          </>
        }
      </OptionsMenu>
      <DuplicateJobModal open={duplicatingJob} job={job} onClose={() => setDuplicatingJob(false)}/>
      <ViewJobUpdatesModal open={viewingUpdates} onClose={() => setViewingUpdates(false)} jobId={!!viewingUpdates && job.id}/>
      <SendMessageModal open={pinging} onClose={() => setPinging(false)} jobIds={[job?.id]}/>
      <AddInvoiceModal open={invoicing} jobId={job?.id} onClose={() => setInvoicing(false)}/>
      <AddMistakeModal open={addingMistake} entity={job} entityType="job" onClose={() => setAddingMistake(false)}/>
    </>
  );
};

export default JobOptionsMenu;