import {Inertia} from '@inertiajs/inertia';
import {usePage} from '@inertiajs/inertia-react';
import {Tooltip} from '@webfox/webfox-ui';
import cls from 'classnames';
import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const BranchSelector = () => {
    const [loading, setLoading] = useState(false);
    const {current_branch_id} = useUser();
    const {branches, branch_lock, branch_temp} = usePage()?.props?.application;

    const [selectedBranch, setSelectedBranch] = useState(() => {
        return branches.find(branch => branch?.id === current_branch_id);
    });

    const onChange = (selectedOption) => {
        Inertia.patch(route('user.branch.update'), {
            branch_id: selectedOption?.id,
        }, {
            onBefore: () => {
                setLoading(true);
            },
            onFinish: () => {
                setLoading(false);
            },
        });
    };

    useEffect(() => {
        setSelectedBranch(branches.find(branch => branch.id === current_branch_id));
    }, [current_branch_id]);

    return (
        <Tooltip content="Branch cannot be changed on this screen" disabled={!branch_lock}>
            <div className={cls('relative flex items-center gap-3 min-w-max px-3 duration-100', branch_lock && !branch_temp && 'opacity-50')}>
                <div className={cls('font-medium', branch_temp ? 'text-blue-400' : 'text-gray-400')}>{!!branch_temp && 'Temporary '}Branch:</div>
                <Select
                    inputId="branch-selector"
                    placeholder="Select a branch..."
                    isClearable
                    isLoading={loading}
                    options={branches}
                    onChange={onChange}
                    value={selectedBranch}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.name}
                    styles={{
                        menu: provided => ({
                            ...provided,
                            minWidth: 'max-content',
                        }),
                    }}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                    openMenuOnFocus
                    className="min-w-48"
                    isDisabled={branch_lock}
                />
            </div>
        </Tooltip>
    );
};

export default BranchSelector;