import {Transition} from '@headlessui/react';
import {mdiAlert, mdiChevronDown, mdiClose, mdiCollapseAll, mdiExpandAll} from '@mdi/js';
import {Icon} from '@mdi/react';
import {
  axios,
  ConfirmationModal,
  FieldContext,
  formatShortDatetime,
  Formik,
  generateTreeFromNestedChildren,
  TextareaField,
  Tree,
  useInertiaForm,
  useTree,
} from '@webfox/webfox-ui';
import cls from 'classnames';
import {ErrorMessage, Form} from 'formik';
import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import useUser from 'Support/Hooks/useUser';
import * as Yup from 'yup';
import route from 'ziggy-js';

const CommentsContext = createContext({submissionRoute: '', onCommentSubmitted: () => {}});

const CommentNode = ({node: {data: comment, ...node}, expanded, onChangeExpand, shouldExpand}) => {
  const [contentContainer, setContentContainer] = useState();
  const [contentExpanded, setContentExpanded] = useState(false);
  const [replying, setReplying] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const contentExpandable = useMemo(() => {
    if (contentContainer) {
      return contentContainer.offsetHeight / parseInt(window.getComputedStyle(contentContainer).lineHeight) >= 4;
    }
    return false;
  }, [comment.content, contentContainer]);

  const edited = useMemo(() => (
    !(comment.created_at === comment.updated_at)
  ), [comment.created_at, comment.updated_at]);

  const user = useUser();
  const [canEdit, canRemove] = useMemo(() => {
    const belongsToAuthed = user.id === comment.user?.id;
    return [belongsToAuthed, user.isAdmin || belongsToAuthed];
  }, [user, comment.user]);

  const {onCommentDeleted, locked} = useContext(CommentsContext);

  const onDelete = () => {
    axios.delete(route('comments.destroy', comment?.id))
      .then(() => {
        onCommentDeleted && onCommentDeleted();
      });
  };

  return (
    <div className="flex flex-col gap-2 mb-2">
      <div className="flex items-start gap-2">
        <img src={comment.user?.avatar} alt={`${comment.user?.name}'s avatar`} className="rounded-full w-10"/>
        <div className="flex flex-col grow">
          <div className="flex gap-2 ml-1 items-center">
            <div className="font-medium text-gray-900">{comment.user?.name}</div>
            <div className="text-xs text-gray-500">{formatShortDatetime(comment.updated_at)} {edited && '(Edited)'}</div>
          </div>
          {editing ?
            <div className="grow">
              <CommentTextField comment={comment} onSubmit={() => setEditing(false)} onCancel={() => setEditing(false)}/>
            </div>
            :
            <div className="px-3 py-2 bg-white border shadow-sm rounded max-w-full">
              <div ref={setContentContainer} className={cls('text-sm whitespace-pre-line', !contentExpanded && 'line-clamp-4')}>
                {comment.content}
              </div>
              {contentExpandable &&
                <button
                  onClick={() => setContentExpanded(!contentExpanded)}
                  type="button"
                  className="flex items-center font-medium text-sm text-indigo-500"
                >
                  <span>Show {contentExpanded ? 'Less' : 'More'}</span>
                </button>
              }
            </div>
          }

          {!(locked || editing) &&
            <div className="ml-1 flex items-center gap-2 text-sm underline text-gray-500">
              <button type="button" onClick={() => setReplying(true)}>Reply</button>
              {canEdit && <button type="button" onClick={() => setEditing(true)}>Edit</button>}
              {canRemove && <button type="button" onClick={() => setDeleting(true)}>Delete</button>}
            </div>
          }
        </div>
      </div>
      <Transition
        show={replying}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 -translate-y-2"
        leave="duration-150 ease-in"
        leaveFrom="opacity-100"
        leaveTo="opacity-0 -translate-y-2"
      >
        <div className="ml-5 flex items-start gap-2 p-1 hover:bg-opacity-75">
          <button type="button" onClick={() => setReplying(false)}>
            <Icon path={mdiClose} className="w-6 mt-4 text-gray-500"/>
          </button>
          <div className="grow">
            <div className="text-sm">
              <span className="text-indigo-500">Replying to </span>
              <span className="font-medium text-gray-900">{comment.user?.name}</span>
            </div>
            <CommentTextField comment={comment} onSubmit={() => setReplying(false)} replying/>
          </div>
        </div>
      </Transition>
      {shouldExpand &&
        <button
          type="button"
          onClick={() => onChangeExpand(!expanded)}
          className="ml-2 flex items-center gap-2 font-medium text-indigo-500">
          <Icon path={mdiChevronDown} className={cls('w-5 duration-150', expanded && 'rotate-180')}/>
          <span>{expanded ? 'Hide' : 'View'} Replies</span>
        </button>
      }
      <ConfirmationModal
        title="Are you sure?"
        open={deleting}
        onConfirm={onDelete}
        onCancel={() => setDeleting(false)}
        confirmText="Delete"
      >
        <div>Are you sure you want to delete this comment?</div>
        {!!node?.children?.length &&
          <div className="flex items-center gap-2 text-amber-500">
            <Icon path={mdiAlert} className="w-8 p-1.5 rounded-full bg-amber-100"/>
            <span>This will also remove all replies</span>
          </div>
        }
      </ConfirmationModal>
    </div>
  );
};

const CommentTextField = ({comment, replying, onSubmit, onCancel}) => {
  const {submissionRoute, onCommentSubmitted} = useContext(CommentsContext);
  const user = useUser();

  const formikProps = useInertiaForm({
    defaultValues: {
      content: (replying || !comment?.content) ? '' : comment.content,
    },
    validationSchema: Yup.object().shape({
      content: Yup.string().max(2000, 'Comments cannot be longer than 2000 characters').required('Comment requires text'),
    }),
    submit: ({put, post, reset}) => {
      const onSuccess = () => {
        reset();
        onSubmit && onSubmit();
        onCommentSubmitted && onCommentSubmitted();
      };
      if (comment?.id) {
        if (replying) {
          post(route('comments.reply', comment.id), {onSuccess});

        } else {
          put(route('comments.update', comment.id), {onSuccess});
        }
      } else {
        post(submissionRoute, {onSuccess});
      }
    },
  });

  return (
    <Formik {...formikProps}>
      {({values, submitDisabled}) => (
        <Form>
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              {!comment?.id && <img src={user?.avatar} alt={`${user?.name}'s Avatar`} className="rounded-full w-10"/>}
              <FieldContext.Provider value={{name: 'content'}}>
                <TextareaField placeholder="Add comment..."/>
              </FieldContext.Provider>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm">
                {onCancel &&
                  <button type="button" onClick={onCancel} className="underline text-gray-500">
                    Cancel
                  </button>
                }
                <ErrorMessage name="content" component="div" className="text-red-500"/>
              </div>
              <div className="flex items-center gap-2">
                <span
                  className={cls('text-sm', values?.content?.length > 2000 ? 'text-red-500' : 'text-gray-500')}>{values?.content.length}/2000</span>
                <button
                  type="submit"
                  className="px-3 py-1 font-medium bg-indigo-500 text-white rounded shadow-sm duration-100 hover:bg-opacity-75 disabled:bg-gray-300"
                  disabled={submitDisabled}
                >
                  {replying ? 'Reply' : 'Submit'}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const CommentSection = ({comments, addRoute, locked, onCommentSubmitted, onCommentDeleted, fieldAtTop}) => {
  const tree = useTree(comments || [], [comments], {
    generatorFunction: generateTreeFromNestedChildren,
    childrenKey: 'all_children',
  });

  const expandAll = useCallback(() => {
    tree.flatNodes.forEach(node => tree.expandNode(node));
  }, [tree]);

  const collapseAll = useCallback(() => {
    tree.flatNodes.forEach(node => tree.collapseNode(node));
  }, [tree]);

  return (
    <CommentsContext.Provider value={{
      locked,
      submissionRoute: addRoute,
      onCommentSubmitted: onCommentSubmitted,
      onCommentDeleted: onCommentDeleted,
    }}>
      <div className="flex flex-col">
        <div className="flex items-center justify-end gap-2">
          <button type="button" onClick={expandAll} className="flex items-center gap-2 px-2 py-1 text-sm bg-gray-100 hover:bg-indigo-50 rounded">
            <Icon path={mdiExpandAll} className="w-5 text-gray-500"/>
            <div>Expand All</div>
          </button>
          <button type="button" onClick={collapseAll} className="flex items-center gap-2 px-2 py-1 text-sm bg-gray-100 hover:bg-indigo-50 rounded">
            <Icon path={mdiCollapseAll} className="w-5 text-gray-500"/>
            <div>Collapse All</div>
          </button>
        </div>
        <div className="flex flex-col gap-2 overflow-auto">
          <Tree
            tree={tree}
            dndOptions={{
                ownProvider: true,
            }}
            components={{
              Node: CommentNode,
            }}
          />
        </div>
        {!locked &&
          <div className={cls(!!fieldAtTop ? 'order-first pb-2 mb-2 border-b' : 'pt-2 mt-1 border-t')}>
            <CommentTextField/>
          </div>
        }
      </div>
    </CommentsContext.Provider>
  );
};

export default CommentSection;