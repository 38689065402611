import {Form, Formik, Modal, PrimaryButton, SecondaryButton, useInertiaForm} from '@webfox/webfox-ui';
import {MessageThreadForm, validationSchema} from 'Forms/Messages/MessageThreadForm';
import React from 'react';
import route from 'ziggy-js';

const AddMessageThreadModal = ({open, onClose}) => {
  const formikProps = useInertiaForm({
    defaultValues: {
      name: '',
      users: [],
    },
    submit: ({post, reset}) => post(route('message-threads.store'), {
      onSuccess: () => {
        onClose();
        reset();
      },
    }),
    validationSchema,
  });

  return (
    <Formik {...formikProps}>
      {({isValid}) => (
        <Modal open={open} onClose={onClose}>
          <Modal.Content as={Form}>
            <Modal.Header title="Add Message Thread"/>
            <div className="space-y-6">
              <MessageThreadForm/>
            </div>
            <Modal.Actions>
              <SecondaryButton type="button" onClick={onClose}>Cancel</SecondaryButton>
              <PrimaryButton type="submit" disabled={!isValid}>Add</PrimaryButton>
            </Modal.Actions>
          </Modal.Content>
        </Modal>
      )}
    </Formik>
  );
};

export default AddMessageThreadModal;