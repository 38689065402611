import {Transition} from '@headlessui/react';
import {Inertia} from '@inertiajs/inertia';
import {mdiAlert, mdiBell, mdiChevronLeft, mdiLoading, mdiPlus, mdiRefresh} from '@mdi/js';
import {Icon} from '@mdi/react';
import {axios, formatShortDatetime, Panel, SecondaryButton} from '@webfox/webfox-ui';
import cls from 'classnames';
import JobPing from 'Components/Pings/JobPing';
import AddMessageThreadModal from 'Forms/Messages/AddMessageThreadModal';
import ThreadIcon from 'Forms/Messages/Partials/ThreadIcon';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const ThreadPreview = ({thread, onClick}) => {
  const hasUnreadPing = useMemo(() => new Date(thread?.last_ping_message?.created_at) > new Date(thread?.current_thread_user?.last_read_at));

  return (
    <button type="button" onClick={onClick} className="flex items-center gap-2 p-2 duration-100 hover:bg-indigo-100 rounded-lg">
      <ThreadIcon thread={thread}/>
      <div
        className={cls('flex-1 flex flex-col min-w-0 items-start', new Date(thread?.last_message_at) > new Date(thread.current_thread_user?.last_read_at) && 'font-semibold')}>
        <div className="text-lg truncate max-w-full">{thread?.name}</div>
        <div className="text-sm truncate max-w-full">{thread?.last_message?.body}</div>
      </div>
      {hasUnreadPing &&
        <div className="relative text-primary">
          <Icon path={mdiBell} className="w-12"/>
          <Icon path={mdiBell} className="absolute inset-0 w-12 animate-ping"/>
        </div>
      }
    </button>
  );
};

const Message = ({body, pings, user, isCurrentUser, created_at}) => (
  <div className={cls('flex items-start gap-2 p-2 max-w-full animate-scale-in', isCurrentUser && 'self-end')}>
    <img src={user?.avatar} alt={`${user.name}'s Avatar`} className={cls('w-8 rounded-full', isCurrentUser && 'order-2')}/>
    <div className={cls('flex flex-col min-w-0', isCurrentUser ? 'items-end' : 'items-start')}>
      {!isCurrentUser && <div className="text-sm font-medium truncate text-gray-700">{user?.name}</div>}
      <div className={cls('p-2 max-w-full break-words rounded-lg', isCurrentUser ? 'bg-blue-500 text-white' : 'bg-gray-100')}>
        <div>{body}</div>
        {!!pings?.length && (
          <div className="flex flex-col p-1 gap-2 bg-white rounded">
            {pings.map(ping => (
              <JobPing key={ping.id} id={ping?.pinged_id}/>
            ))}
          </div>
        )}
      </div>
      <div className="self-end text-xs text-gray-300">{formatShortDatetime(created_at)}</div>
    </div>
  </div>
);

const Thread = ({threadId, onBack, refreshThreads}) => {
  const [thread, setThread] = useState(null);
  const [editing, setEditing] = useState(false);
  const [{messages, hasMore}, setMessageData] = useState({messages: [], hasMore: false});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [timeToRefresh, setTimeToRefresh] = useState(60);
  const messageContainer = useRef();
  const user = useUser();

  useEffect(() => {
    if (threadId) {
      setLoading(true);
      axios.get(route('message-threads.show', threadId)).then(({data}) => {
        setThread(data?.thread);
        setMessageData({messages: data?.messages?.data?.reverse(), hasMore: data?.messages?.current_page < data?.messages?.last_page});
      }).finally(() => setLoading(false));
    }
  }, [threadId]);

  const updateName = useCallback((name) => {
    if (!!thread && name && name !== thread?.name) {
      return Inertia.put(route('message-threads.update', thread?.id), {name}, {
        onSuccess: () => setThread(thread => ({...thread, name})),
        onFinish: () => setEditing(false),
      });
    }
    setEditing(false);
  }, [thread]);

  const refreshMessages = useCallback(() => {
    if (threadId) {
      setLoading(true);
      axios.get(route('message-threads.messages', {
        messageThread: threadId,
        afterMessage: messages[messages?.length - 1]?.id,
        noPaginate: 1,
      })).then(({data}) => {
        setMessageData(prevData => ({
          ...prevData,
          messages: [...prevData.messages, ...data],
        }));
        setTimeToRefresh(60);
      }).finally(() => {
        setLoading(false);
        refreshThreads();
      });
    }
  }, [threadId, messages, refreshThreads]);

  const fetchPreviousMessages = useCallback(() => {
    if (threadId) {
      setLoading(true);
      axios.get(route('message-threads.messages', {messageThread: threadId, beforeMessage: messages[0]?.id})).then(({data}) => {
        setMessageData(prevData => ({
          messages: [...[...data?.data].reverse(), ...prevData.messages],
          hasMore: data?.current_page < data?.last_page,
        }));
      }).finally(() => setLoading(false));
    }
  }, [threadId, messages]);

  const sendMessage = useCallback(() => {
    if (threadId && message) {
      setLoading(true);
      axios.post(route('messages.send', threadId), {body: message}).then(() => {
        refreshMessages();
        setMessage('');
      }).finally(() => setLoading(false));
    }
  }, [threadId, message]);

  const scrollToBottom = useCallback(() => {
    if (messageContainer.current) {
      messageContainer.current.scrollTo({
        top: messageContainer.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages[messages?.length - 1], scrollToBottom]);

  useEffect(() => {
    if (threadId) {
      const tick = setTimeout(() => {
        setTimeToRefresh(prevTime => prevTime - 1);
      }, 1000);

      if (timeToRefresh <= 0) {
        refreshMessages();
        setTimeToRefresh(60);
      }

      return () => clearTimeout(tick);
    }
  }, [threadId, timeToRefresh]);

  return (
    <div className="flex flex-col h-full gap-2 p-4">
      <div className="flex items-center gap-2 pb-4 border-b">
        <button type="button" onClick={onBack} className="p-2 text-gray-400 bg-gray-100 hover:bg-gray-200 rounded-full">
          <Icon path={mdiChevronLeft} className="w-6"/>
        </button>
        {thread &&
          <>
            <ThreadIcon thread={thread}/>
            {editing ?
              <input
                autoFocus
                defaultValue={thread?.name}
                onBlur={e => updateName(e.target.value)}
                onKeyDown={e => e.key === 'Enter' && updateName(e.target.value)}
                className="grow text-lg ring-offset-2 focus:ring-2 focus:outline-none bg-gray-50 rounded"
              />
              :
              <div className="text-lg truncate" onClick={() => setEditing(true)}>{thread?.name}</div>
            }
          </>
        }
      </div>
      <div ref={messageContainer} className="relative grow overflow-y-auto">
        <div className="flex flex-col justify-end items-start min-h-full">
          {hasMore &&
            <button
              type="button"
              onClick={fetchPreviousMessages}
              className="self-stretch p-2 text-center text-white bg-blue-500 hover:bg-opacity-75 disabled:bg-gray-500 rounded-full"
              disabled={loading}
            >
              Load Previous Messages
            </button>
          }
          {messages?.length > 0 ?
            messages?.map((message) => (
              <Message key={message.id} {...message} isCurrentUser={message?.user_id === user?.id}/>
            ))
            :
            <div className="grow self-center text-center text-gray-400">No Messages</div>
          }
        </div>
        {loading &&
          <div className="absolute inset-0 flex items-center justify-center">
            <Icon path={mdiLoading} spin={1} className="p-2 text-white bg-blue-500 w-12 rounded-full"/>
          </div>
        }
      </div>
      <div className="flex flex-col pt-4 gap-2 border-t">
        <div className="flex items-center justify-between gap-2">
          <div className="text-gray-700">Refreshing in {timeToRefresh} seconds</div>
          <button type="button" onClick={refreshMessages} disabled={loading} className="p-1 bg-blue-500 hover:bg-opacity-75 rounded-full">
            <Icon path={loading ? mdiLoading : mdiRefresh} spin={!!loading} className="w-6 text-white"/>
          </button>
        </div>
        <textarea
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              sendMessage();
            }
          }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="flex-grow p-2 resize-none border rounded-lg"
        />
        <button
          type="button"
          onClick={sendMessage}
          className="self-end p-2 text-white bg-blue-500 disabled:bg-gray-500 hover:bg-opacity-75 rounded-lg"
          disabled={loading || !message}
        >
          Send
        </button>
      </div>
    </div>
  );
};

const ViewMessagesPanel = ({open, onClose, threads, erroredAt, onUpdate}) => {
  const [creating, setCreating] = useState(false);
  const [thread, setThread] = useState(null);

  const refreshThreads = useMemo(() => typeof onUpdate === 'function' ? onUpdate : () => {}, [onUpdate]);

  const changeThread = useCallback((thread) => {
    setThread(thread);
    if (thread) {
      refreshThreads();
    }
  }, [refreshThreads]);

  return (
    <>
      <Panel open={open} onClose={onClose}>
        <div className="w-screen max-w-md">
          <Panel.Body>
            <div className="flex flex-col h-full">
              <Panel.Header title="Messages"/>
              <div className="relative grow overflow-x-hidden">
                <Transition
                  as="div"
                  show={!thread}
                  className="absolute inset-0 flex flex-col gap-2 p-4 overflow-y-auto"
                  enter="duration-300"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  {erroredAt ?
                    <div className="flex items-center justify-center gap-2">
                      <Icon path={mdiAlert} className="w-10 text-amber-500"/>
                      <div className="flex flex-col gap-1">
                        <div className="text-lg">Unable to load messages</div>
                        <div className="text-sm">Please check your internet connection</div>
                        <div className="text-sm">If this problem persists please contact an administrator</div>
                      </div>
                    </div>
                    :
                    <>
                      <SecondaryButton type="button" iconStart={mdiPlus} onClick={() => setCreating(true)}>
                        Create Thread
                      </SecondaryButton>
                      {threads?.length ? threads.map(thread => (
                        <ThreadPreview thread={thread} onClick={() => changeThread(thread.id)}/>
                      )) : (
                        <div className="flex items-center justify-center p-4 bg-gray-100 text-gray-400 text-lg rounded-lg">
                          No Threads
                        </div>
                      )}
                    </>
                  }

                </Transition>

                <Transition
                  as="div"
                  show={!!thread}
                  className="absolute inset-0"
                  enter="duration-300"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="duration-300"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Thread threadId={thread} onBack={() => setThread(null)} refreshThreads={refreshThreads}/>
                </Transition>
              </div>
            </div>
          </Panel.Body>
        </div>
      </Panel>
      <AddMessageThreadModal open={creating} onClose={() => setCreating(false)}/>
    </>
  );
};

export default ViewMessagesPanel;