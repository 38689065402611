import {Link} from '@inertiajs/inertia-react';
import {mdiArchive} from '@mdi/js';
import {Icon} from '@mdi/react';
import Lesson from 'Components/Lessons/Lesson';
import React from 'react';
import route from 'ziggy-js';

const Storage = () => (
  <Lesson
    title="Storage"
    subtitle="Locations where work order items are stored"
    sideClassName="bg-gradient-to-t from-amber-500 to-transparent"
    side={
      <div className="relative w-20 h-20 p-2 rounded-full bg-amber-200">
        <Icon path={mdiArchive} className="text-amber-800"/>
      </div>
    }
  >
    <div className="space-y-2">
      <section>
        <h3 className="font-medium">What is Storage?</h3>
        <p className="text-sm text-gray-700">
          Storage is where work order items are designated to be stored when they are not being processed.
          Currently storage consists of shelves, each of which has a name and a number of slots. These slots
          can be filled with boxes, which are containers for work order items.
        </p>
      </section>
      <section>
        <h3 className="font-medium">How do I use Storage?</h3>
        <p className="text-sm text-gray-700">
          Storage locations can be created by clicking the 'Add Location' button in the top right of the
          <Link href={route('locations.index')} className="text-blue-500 underline">Storage</Link> page.
          Here you will be able to create shelves and boxes, and assign them an appropriate name, and amount of slots.
        </p>
        <p className="text-sm text-gray-700 mt-1">
          Once you have created a storage location, you can assign it to a work order by clicking the 'Assign&nbsp;Storage'
          button on a work order page.
        </p>
      </section>
      <section>
        <h3 className="font-medium">Can I manipulate Storage locations?</h3>
        <p className="text-sm text-gray-700">
          Yes, you can edit and delete storage locations by clicking the 'Edit' button on a storage location on the <Link
          href={route('locations.index')} className="text-blue-500 underline">Storage</Link> page.
          Storage locations can also be moved by dragging and dropping them, provided the destination location has enough slots.
        </p>
      </section>
    </div>
  </Lesson>
);

Object.assign(Storage, {
  lessonName: 'Storage',
  lessonKey: 'storage',
  modalSize: '2xl',
  group: 'Settings',
  roleRestrictions: ['admin'],
  keywords: ['storage', 'locations', 'shelves', 'boxes'],
});

export default Storage;