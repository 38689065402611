import {mdiBug} from '@mdi/js';
import {Icon} from '@mdi/react';
import Lesson from 'Components/Lessons/Lesson';
import React from 'react';
import useUser from 'Support/Hooks/useUser';

const Bugs = () => (
  <Lesson
    title="Bugs"
    subtitle="How to report bugs"
    sideClassName="bg-gradient-to-t from-red-500 to-transparent"
    side={
      <div className="relative w-20 h-20 p-2 rounded-full bg-red-200">
        <Icon path={mdiBug} className="text-red-800"/>
      </div>
    }
  >
    <div className="space-y-2">
      <section>
        <h3 className="font-medium">What are Bugs?</h3>
        <p className="text-sm text-gray-700">
          Bugs are errors in the software. They can be anything from a small typo to a major issue that
          prevents the software from working. If you find a bug, please report it so that it can be fixed
        </p>
      </section>
      <section>
        <h3 className="font-medium">How do I report a bug?</h3>
        <p className="text-sm text-gray-700">
          If you find a bug, please report it to {['admin'].includes(useUser()?.role?.value) ? 'a developer' : 'an administrator'}
        </p>
      </section>
    </div>
  </Lesson>
);

Object.assign(Bugs, {
  lessonName: 'Bugs',
  lessonKey: 'bugs',
  modalSize: '2xl',
  group: 'Reporting',
  keywords: ['bug', 'report', 'error'],
});

export default Bugs;