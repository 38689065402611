import {createInertiaApp, InertiaHead, PageResolver} from '@inertiajs/inertia-react';
import {InertiaProgress, ProgressSettings} from '@inertiajs/progress';
import * as React from 'react';
import {ComponentType, createElement, PropsWithChildren, ReactElement, StrictMode, useEffect} from 'react';
import {createRoot} from 'react-dom/client'
import {FormErrorContextProvider, InitialApplicationContext} from './Contexts';
import 'Support/yupExtensions';
import {accountMenuItems, navItems} from "../MainLayoutConfig";
import {toast} from "react-toastify";
import AccountMenu from "../Components/AccountMenu";


// export const initGoogleAnalytics = () => {
//   if (typeof window.gtag === 'function') {
//     let initialPageView = true;
//     Inertia.on('navigate', (event) => {
//       if (initialPageView) {
//         initialPageView = false;
//       } else {
//         window.gtag('event', 'page_view', {
//           'page_location': event.detail.page.url,
//         });
//       }
//     });
//   }
// };
//
// export const initErrorTracking = async () => {
//   if (window.sentryDsn) {
//     const Sentry = await import('@sentry/react');
//     const {BrowserTracing} = await import('@sentry/tracing');
//
//     Sentry.init({
//       dsn: window.sentryDsn,
//       integrations: [new BrowserTracing()],
//       environment: window.sentryEnvironment,
//       tracesSampleRate: window.sentryTracesSampleRate,
//     });
//   }
// };

export const initInertiaApp = ({
                                 resolve,
                                 layout: MainLayout,
                                 analytics = true,
                                 errorTracking = true,
                                 inertiaProgress = true,
                               }: {
  resolve: PageResolver;
  layout: ComponentType<PropsWithChildren>;
  analytics?: boolean;
  errorTracking?: boolean;
  inertiaProgress?: boolean | ProgressSettings;
}) => {
  // if (errorTracking) {
  //   initErrorTracking();
  // }
  //
  //
  // if (analytics) {
  //   initGoogleAnalytics();
  // }

  if (inertiaProgress) {
    InertiaProgress.init(typeof inertiaProgress === 'boolean' ? {
      showSpinner: true,
    } : inertiaProgress);
  }


  return createInertiaApp<Window['inertiaInitialPageData']['props']>({
    resolve,
    page: window.inertiaInitialPageData,
    setup({el, App, props}) {
      createRoot(el).render((
        <StrictMode>
          <InitialApplicationContext.Provider value={{application: props.initialPage.props.application}}>
            <App {...props}>
              {({key, props, Component}) => {
                // @ts-ignore
                const componentLayout: (page: ReactElement) => ReactElement | Array<(page: ReactElement) => ReactElement> = Component.layout;
                const page = createElement(Component, {key, ...props});
                useEffect(() => {
                  if (Array.isArray(page.props.flash)) {
                    page.props.flash.forEach(({type, message}) => {
                      toast(message, {type});
                    });
                  }
                }, [JSON.stringify(page.props.flash)]);
                let children;
                if (Array.isArray(componentLayout)) {
                  children = componentLayout.concat(page).reverse().reduce((children, Layout) => createElement(Layout, {children}));
                } else if (typeof componentLayout === 'function') {
                  children = componentLayout(page);
                } else {
                  children = <MainLayout
                      children={page}
                      navItems={navItems(page.props.application.user)}
                      accountMenu={<AccountMenu accountMenuItems={accountMenuItems}/>}
                      site_name={page.props.application.site_name}
                      production={page.props.application.production}
                  />;
                }

                return (
                  <FormErrorContextProvider>
                    <InertiaHead key={page.props.page_title}>
                      <title>{(page.props.page_title ? `${page.props.page_title} | ` : '') + page.props.application.site_name}</title>
                    </InertiaHead>
                    {children}
                  </FormErrorContextProvider>
                );
              }}
            </App>
          </InitialApplicationContext.Provider>
        </StrictMode>
      ));
    },
  });
};