import {Link} from '@inertiajs/inertia-react';
import {mdiLoading, mdiTag} from '@mdi/js';
import {Icon} from '@mdi/react';
import {axios} from '@webfox/webfox-ui';
import Lesson from 'Components/Lessons/Lesson';
import React, {useCallback, useState} from 'react';
import useUser from 'Support/Hooks/useUser';
import route from 'ziggy-js';

const CAN_EDIT = ['admin'];

const Tags = () => {
  const [tagTypes, setTagTypes] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = useUser();

  const loadTags = useCallback(() => {
    setLoading(true);
    axios.get(route('tags.types')).then(({data}) => {
      setTagTypes(data.map(type => type?.label));
    }).finally(() => setLoading(false));
  }, []);

  return (
    <Lesson
      title="Tags"
      subtitle="A useful tool for filtering work orders"
      sideClassName="bg-gradient-to-t from-cyan-500 to-transparent"
      side={
        <div className="relative w-20 h-20 p-2 rounded-full bg-cyan-200">
          <Icon path={mdiTag} className="text-cyan-800"/>
        </div>
      }
    >
      <div className="space-y-2">
        <section>
          <h3 className="font-medium">What are Tags?</h3>
          <p className="text-sm text-gray-700">
            Tags are a way to add additional information to various objects in the system. For example, you can add tags to
            products, customers, and work orders.
          </p>
        </section>
        <section>
          <h3 className="font-medium">How are they useful?</h3>
          <p className="text-sm text-gray-700">
            Tags are a great way to filter objects based on the information you enter. For example, if you have customers
            that do not want to be contacted by phone, you can add a tag to those customers and then filter all customers
            that have that tag by searching for the tag name
          </p>
        </section>
        <section>
          <h3 className="font-medium">How do they work?</h3>
          <p className="text-sm text-gray-700">
            When you add a tag to an object, you can then search for that tag by typing the 'tag:' followed by the tag name
            into the search bar
          </p>
        </section>
        {CAN_EDIT.includes(user?.role?.value) && (
          <>
            <section>
              <h3 className="font-medium">How do I create tags?</h3>
              <p className="text-sm text-gray-700">
                Tags can be created by clicking the 'Create Tag' button on the <Link href={route('tags.index')}
                                                                                     className="text-blue-500 underline">Tags</Link> page
              </p>
            </section>
            <section>
              <h3 className="font-medium">What types of tags can I create?</h3>
              {loading ? <Icon path={mdiLoading} spin={1} className="w-6 text-gray-700"/> :
                !!tagTypes ?
                  <>
                    <p className="text-sm text-gray-700">
                      Currently supported tag types are:
                    </p>
                    <ul className="list-disc list-inside text-sm">
                      {tagTypes?.map(type => (
                        <li key={type}>{type}</li>
                      ))}
                    </ul>
                  </>
                  :
                  <button onClick={loadTags} className="px-2 py-1 duration-100 bg-gray-100 hover:bg-blue-100 rounded">
                    Load Tag Types
                  </button>
              }
            </section>
          </>
        )}
      </div>
    </Lesson>
  );
};

Object.assign(Tags, {
  lessonName: 'Tags',
  lessonKey: 'tags',
  modalSize: '2xl',
  group: 'Filtering',
  keywords: ['tags', 'filter', 'search', 'objects'],
});

export default Tags;