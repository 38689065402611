import {mdiClose} from '@mdi/js';
import {FieldContext, SelectField} from '@webfox/webfox-ui';
import ListField from 'Components/Forms/ListField';
import TagOption from 'Components/Forms/SelectOptions/TagOption';
import Tag from 'Components/Tags/Tag';
import {useField} from 'formik';
import React, {useContext, useMemo} from 'react';


const TagSelectField = ({availableTags, tags, remove}) => {
  const {name} = useContext(FieldContext);
  const [field] = useField(name);

  return (
    !!field.value ?
      <Tag {...tags.find(tag => tag.id === field.value)}
        endButton={
          <Tag.Button
            onClick={remove}
            icon={mdiClose}
          />
      }
      />
      :
      <div className="w-48">
        <SelectField
          defaultMenuIsOpen
          placeholder="Select Tag"
          components={{
            Option: TagOption,
          }}
          options={availableTags}
          valueKey="id"
        />
      </div>
  );
};

const TagField = ({tags}) => {
  const {name} = useContext(FieldContext);
  const [field] = useField(name);

  const availableTags = useMemo(() => tags.filter(tag => !field.value?.some(fieldTag => fieldTag.id === tag.id)), [field.value])

  return (
    <ListField addDisabled={!availableTags?.length || (!!field.value.length && field.value.some(val => !val))} entityName="tag"
               className="flex flex-wrap items-center gap-2">
      {({SubFieldWrapper, removeItem}) => (
        <SubFieldWrapper name="id">
          <TagSelectField remove={removeItem} tags={tags} availableTags={availableTags}/>
        </SubFieldWrapper>
      )}
    </ListField>
  );
}

export default TagField;