import {usePage} from '@inertiajs/inertia-react';
import {mdiLoading, mdiSync} from '@mdi/js';
import {Icon} from '@mdi/react';
import {formatShortDatetime} from '@webfox/webfox-ui';
import axios from 'axios';
import cls from 'classnames';
import React, {useEffect, useState} from 'react';
import route from 'ziggy-js';

const MyobSync = () => {
    const myob = usePage()?.props?.application?.integrations?.myob;
    const [syncing, setSyncing] = useState(myob?.syncing);
    const [lastSync, setLastSync] = useState(myob?.last_sync);

    const sync = () => {
        setSyncing(true);
        axios.get(route('integrations.myob.sync')).then(() => {
            setSyncing(true);
        }).catch(() => {
            setSyncing(false);
        });
    };

    useEffect(() => {
        if (syncing) {
            const controller = new AbortController();

            const interval = setInterval(() => {
                axios.get(route('integrations.myob.sync-status'), {
                    signal: controller.signal,
                }).then(({data}) => {
                    if (data.syncing) {
                        setLastSync(data.last_sync_finished);
                    } else {
                        setSyncing(false);
                        setLastSync(data.last_sync_finished);
                    }
                });
            }, 5000);

            return () => {
                controller.abort();
                clearInterval(interval);
            };
        }
    }, [syncing]);

    useEffect(() => {
        setLastSync(myob?.last_sync);
    }, [myob?.last_sync]);

    return (
        <div className="relative flex items-center gap-2 text-purple-600 pl-3">
            <span className="block text-xs font-medium px-1 py-0.5 bg-purple-200 rounded">MYOB</span>
            <button type="button" disabled={syncing} onClick={sync} className="rounded-full bg-purple-200 p-0.5 disabled:bg-opacity-0 duration-100">
                <Icon path={syncing ? mdiLoading : mdiSync} className={cls('w-4 h-4', syncing && 'animate-spin')}/>
            </button>
            {syncing ?
                <span className="text-xs font-medium">Syncing...</span>
                : <span className="text-xs">{lastSync ? <>Last sync: {formatShortDatetime(lastSync)}</> : 'Not Synced'}</span>
            }
        </div>
    );
};

export default MyobSync;